/**
 * Kubernetes
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.22.2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * HPAScalingPolicy is a single policy which must hold true for a specified past interval.
 */
export class V2beta2HPAScalingPolicy {
  /**
   * PeriodSeconds specifies the window of time for which the policy should hold true. PeriodSeconds must be greater than zero and less than or equal to 1800 (30 min).
   */
  'periodSeconds': number;
  /**
   * Type is used to specify the scaling policy.
   */
  'type': string;
  /**
   * Value contains the amount of change which is permitted by the policy. It must be greater than zero
   */
  'value': number;

  static discriminator: string | undefined = undefined;

  static attributeTypeMap: Array<{ name: string; baseName: string; type: string }> = [
    {
      name: 'periodSeconds',
      baseName: 'periodSeconds',
      type: 'number',
    },
    {
      name: 'type',
      baseName: 'type',
      type: 'string',
    },
    {
      name: 'value',
      baseName: 'value',
      type: 'number',
    },
  ];

  static getAttributeTypeMap() {
    return V2beta2HPAScalingPolicy.attributeTypeMap;
  }
}
