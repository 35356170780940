/**
 * Kubernetes
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.22.2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Represents a Photon Controller persistent disk resource.
 */
export class V1PhotonPersistentDiskVolumeSource {
  /**
   * Filesystem type to mount. Must be a filesystem type supported by the host operating system. Ex. \"ext4\", \"xfs\", \"ntfs\". Implicitly inferred to be \"ext4\" if unspecified.
   */
  'fsType'?: string;
  /**
   * ID that identifies Photon Controller persistent disk
   */
  'pdID': string;

  static discriminator: string | undefined = undefined;

  static attributeTypeMap: Array<{ name: string; baseName: string; type: string }> = [
    {
      name: 'fsType',
      baseName: 'fsType',
      type: 'string',
    },
    {
      name: 'pdID',
      baseName: 'pdID',
      type: 'string',
    },
  ];

  static getAttributeTypeMap() {
    return V1PhotonPersistentDiskVolumeSource.attributeTypeMap;
  }
}
