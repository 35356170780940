/**
 * Kubernetes
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.22.2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Represents a Fibre Channel volume. Fibre Channel volumes can only be mounted as read/write once. Fibre Channel volumes support ownership management and SELinux relabeling.
 */
export class V1FCVolumeSource {
  /**
   * Filesystem type to mount. Must be a filesystem type supported by the host operating system. Ex. \"ext4\", \"xfs\", \"ntfs\". Implicitly inferred to be \"ext4\" if unspecified.
   */
  'fsType'?: string;
  /**
   * Optional: FC target lun number
   */
  'lun'?: number;
  /**
   * Optional: Defaults to false (read/write). ReadOnly here will force the ReadOnly setting in VolumeMounts.
   */
  'readOnly'?: boolean;
  /**
   * Optional: FC target worldwide names (WWNs)
   */
  'targetWWNs'?: string[];
  /**
   * Optional: FC volume world wide identifiers (wwids) Either wwids or combination of targetWWNs and lun must be set, but not both simultaneously.
   */
  'wwids'?: string[];

  static discriminator: string | undefined = undefined;

  static attributeTypeMap: Array<{ name: string; baseName: string; type: string }> = [
    {
      name: 'fsType',
      baseName: 'fsType',
      type: 'string',
    },
    {
      name: 'lun',
      baseName: 'lun',
      type: 'number',
    },
    {
      name: 'readOnly',
      baseName: 'readOnly',
      type: 'boolean',
    },
    {
      name: 'targetWWNs',
      baseName: 'targetWWNs',
      type: 'Array<string>',
    },
    {
      name: 'wwids',
      baseName: 'wwids',
      type: 'Array<string>',
    },
  ];

  static getAttributeTypeMap() {
    return V1FCVolumeSource.attributeTypeMap;
  }
}
