import localVarRequest from 'request';

export * from './admissionregistrationV1ServiceReference';
export * from './admissionregistrationV1WebhookClientConfig';
export * from './apiextensionsV1ServiceReference';
export * from './apiextensionsV1WebhookClientConfig';
export * from './apiregistrationV1ServiceReference';
export * from './authenticationV1TokenRequest';
export * from './coreV1EndpointPort';
export * from './coreV1Event';
export * from './coreV1EventList';
export * from './coreV1EventSeries';
export * from './discoveryV1EndpointPort';
export * from './eventsV1Event';
export * from './eventsV1EventList';
export * from './eventsV1EventSeries';
export * from './storageV1TokenRequest';
export * from './v1APIGroup';
export * from './v1APIGroupList';
export * from './v1APIResource';
export * from './v1APIResourceList';
export * from './v1APIService';
export * from './v1APIServiceCondition';
export * from './v1APIServiceList';
export * from './v1APIServiceSpec';
export * from './v1APIServiceStatus';
export * from './v1APIVersions';
export * from './v1AWSElasticBlockStoreVolumeSource';
export * from './v1Affinity';
export * from './v1AggregationRule';
export * from './v1AttachedVolume';
export * from './v1AzureDiskVolumeSource';
export * from './v1AzureFilePersistentVolumeSource';
export * from './v1AzureFileVolumeSource';
export * from './v1Binding';
export * from './v1BoundObjectReference';
export * from './v1CSIDriver';
export * from './v1CSIDriverList';
export * from './v1CSIDriverSpec';
export * from './v1CSINode';
export * from './v1CSINodeDriver';
export * from './v1CSINodeList';
export * from './v1CSINodeSpec';
export * from './v1CSIPersistentVolumeSource';
export * from './v1CSIVolumeSource';
export * from './v1Capabilities';
export * from './v1CephFSPersistentVolumeSource';
export * from './v1CephFSVolumeSource';
export * from './v1CertificateSigningRequest';
export * from './v1CertificateSigningRequestCondition';
export * from './v1CertificateSigningRequestList';
export * from './v1CertificateSigningRequestSpec';
export * from './v1CertificateSigningRequestStatus';
export * from './v1CinderPersistentVolumeSource';
export * from './v1CinderVolumeSource';
export * from './v1ClientIPConfig';
export * from './v1ClusterRole';
export * from './v1ClusterRoleBinding';
export * from './v1ClusterRoleBindingList';
export * from './v1ClusterRoleList';
export * from './v1ComponentCondition';
export * from './v1ComponentStatus';
export * from './v1ComponentStatusList';
export * from './v1Condition';
export * from './v1ConfigMap';
export * from './v1ConfigMapEnvSource';
export * from './v1ConfigMapKeySelector';
export * from './v1ConfigMapList';
export * from './v1ConfigMapNodeConfigSource';
export * from './v1ConfigMapProjection';
export * from './v1ConfigMapVolumeSource';
export * from './v1Container';
export * from './v1ContainerImage';
export * from './v1ContainerPort';
export * from './v1ContainerState';
export * from './v1ContainerStateRunning';
export * from './v1ContainerStateTerminated';
export * from './v1ContainerStateWaiting';
export * from './v1ContainerStatus';
export * from './v1ControllerRevision';
export * from './v1ControllerRevisionList';
export * from './v1CronJob';
export * from './v1CronJobList';
export * from './v1CronJobSpec';
export * from './v1CronJobStatus';
export * from './v1CrossVersionObjectReference';
export * from './v1CustomResourceColumnDefinition';
export * from './v1CustomResourceConversion';
export * from './v1CustomResourceDefinition';
export * from './v1CustomResourceDefinitionCondition';
export * from './v1CustomResourceDefinitionList';
export * from './v1CustomResourceDefinitionNames';
export * from './v1CustomResourceDefinitionSpec';
export * from './v1CustomResourceDefinitionStatus';
export * from './v1CustomResourceDefinitionVersion';
export * from './v1CustomResourceSubresourceScale';
export * from './v1CustomResourceSubresources';
export * from './v1CustomResourceValidation';
export * from './v1DaemonEndpoint';
export * from './v1DaemonSet';
export * from './v1DaemonSetCondition';
export * from './v1DaemonSetList';
export * from './v1DaemonSetSpec';
export * from './v1DaemonSetStatus';
export * from './v1DaemonSetUpdateStrategy';
export * from './v1DeleteOptions';
export * from './v1Deployment';
export * from './v1DeploymentCondition';
export * from './v1DeploymentList';
export * from './v1DeploymentSpec';
export * from './v1DeploymentStatus';
export * from './v1DeploymentStrategy';
export * from './v1DownwardAPIProjection';
export * from './v1DownwardAPIVolumeFile';
export * from './v1DownwardAPIVolumeSource';
export * from './v1EmptyDirVolumeSource';
export * from './v1Endpoint';
export * from './v1EndpointAddress';
export * from './v1EndpointConditions';
export * from './v1EndpointHints';
export * from './v1EndpointSlice';
export * from './v1EndpointSliceList';
export * from './v1EndpointSubset';
export * from './v1Endpoints';
export * from './v1EndpointsList';
export * from './v1EnvFromSource';
export * from './v1EnvVar';
export * from './v1EnvVarSource';
export * from './v1EphemeralContainer';
export * from './v1EphemeralVolumeSource';
export * from './v1EventSource';
export * from './v1Eviction';
export * from './v1ExecAction';
export * from './v1ExternalDocumentation';
export * from './v1FCVolumeSource';
export * from './v1FlexPersistentVolumeSource';
export * from './v1FlexVolumeSource';
export * from './v1FlockerVolumeSource';
export * from './v1ForZone';
export * from './v1GCEPersistentDiskVolumeSource';
export * from './v1GitRepoVolumeSource';
export * from './v1GlusterfsPersistentVolumeSource';
export * from './v1GlusterfsVolumeSource';
export * from './v1GroupVersionForDiscovery';
export * from './v1HTTPGetAction';
export * from './v1HTTPHeader';
export * from './v1HTTPIngressPath';
export * from './v1HTTPIngressRuleValue';
export * from './v1Handler';
export * from './v1HorizontalPodAutoscaler';
export * from './v1HorizontalPodAutoscalerList';
export * from './v1HorizontalPodAutoscalerSpec';
export * from './v1HorizontalPodAutoscalerStatus';
export * from './v1HostAlias';
export * from './v1HostPathVolumeSource';
export * from './v1IPBlock';
export * from './v1ISCSIPersistentVolumeSource';
export * from './v1ISCSIVolumeSource';
export * from './v1Ingress';
export * from './v1IngressBackend';
export * from './v1IngressClass';
export * from './v1IngressClassList';
export * from './v1IngressClassParametersReference';
export * from './v1IngressClassSpec';
export * from './v1IngressList';
export * from './v1IngressRule';
export * from './v1IngressServiceBackend';
export * from './v1IngressSpec';
export * from './v1IngressStatus';
export * from './v1IngressTLS';
export * from './v1JSONSchemaProps';
export * from './v1Job';
export * from './v1JobCondition';
export * from './v1JobList';
export * from './v1JobSpec';
export * from './v1JobStatus';
export * from './v1JobTemplateSpec';
export * from './v1KeyToPath';
export * from './v1LabelSelector';
export * from './v1LabelSelectorRequirement';
export * from './v1Lease';
export * from './v1LeaseList';
export * from './v1LeaseSpec';
export * from './v1Lifecycle';
export * from './v1LimitRange';
export * from './v1LimitRangeItem';
export * from './v1LimitRangeList';
export * from './v1LimitRangeSpec';
export * from './v1ListMeta';
export * from './v1LoadBalancerIngress';
export * from './v1LoadBalancerStatus';
export * from './v1LocalObjectReference';
export * from './v1LocalSubjectAccessReview';
export * from './v1LocalVolumeSource';
export * from './v1ManagedFieldsEntry';
export * from './v1MutatingWebhook';
export * from './v1MutatingWebhookConfiguration';
export * from './v1MutatingWebhookConfigurationList';
export * from './v1NFSVolumeSource';
export * from './v1Namespace';
export * from './v1NamespaceCondition';
export * from './v1NamespaceList';
export * from './v1NamespaceSpec';
export * from './v1NamespaceStatus';
export * from './v1NetworkPolicy';
export * from './v1NetworkPolicyEgressRule';
export * from './v1NetworkPolicyIngressRule';
export * from './v1NetworkPolicyList';
export * from './v1NetworkPolicyPeer';
export * from './v1NetworkPolicyPort';
export * from './v1NetworkPolicySpec';
export * from './v1Node';
export * from './v1NodeAddress';
export * from './v1NodeAffinity';
export * from './v1NodeCondition';
export * from './v1NodeConfigSource';
export * from './v1NodeConfigStatus';
export * from './v1NodeDaemonEndpoints';
export * from './v1NodeList';
export * from './v1NodeSelector';
export * from './v1NodeSelectorRequirement';
export * from './v1NodeSelectorTerm';
export * from './v1NodeSpec';
export * from './v1NodeStatus';
export * from './v1NodeSystemInfo';
export * from './v1NonResourceAttributes';
export * from './v1NonResourceRule';
export * from './v1ObjectFieldSelector';
export * from './v1ObjectMeta';
export * from './v1ObjectReference';
export * from './v1Overhead';
export * from './v1OwnerReference';
export * from './v1PersistentVolume';
export * from './v1PersistentVolumeClaim';
export * from './v1PersistentVolumeClaimCondition';
export * from './v1PersistentVolumeClaimList';
export * from './v1PersistentVolumeClaimSpec';
export * from './v1PersistentVolumeClaimStatus';
export * from './v1PersistentVolumeClaimTemplate';
export * from './v1PersistentVolumeClaimVolumeSource';
export * from './v1PersistentVolumeList';
export * from './v1PersistentVolumeSpec';
export * from './v1PersistentVolumeStatus';
export * from './v1PhotonPersistentDiskVolumeSource';
export * from './v1Pod';
export * from './v1PodAffinity';
export * from './v1PodAffinityTerm';
export * from './v1PodAntiAffinity';
export * from './v1PodCondition';
export * from './v1PodDNSConfig';
export * from './v1PodDNSConfigOption';
export * from './v1PodDisruptionBudget';
export * from './v1PodDisruptionBudgetList';
export * from './v1PodDisruptionBudgetSpec';
export * from './v1PodDisruptionBudgetStatus';
export * from './v1PodIP';
export * from './v1PodList';
export * from './v1PodReadinessGate';
export * from './v1PodSecurityContext';
export * from './v1PodSpec';
export * from './v1PodStatus';
export * from './v1PodTemplate';
export * from './v1PodTemplateList';
export * from './v1PodTemplateSpec';
export * from './v1PolicyRule';
export * from './v1PortStatus';
export * from './v1PortworxVolumeSource';
export * from './v1Preconditions';
export * from './v1PreferredSchedulingTerm';
export * from './v1PriorityClass';
export * from './v1PriorityClassList';
export * from './v1Probe';
export * from './v1ProjectedVolumeSource';
export * from './v1QuobyteVolumeSource';
export * from './v1RBDPersistentVolumeSource';
export * from './v1RBDVolumeSource';
export * from './v1ReplicaSet';
export * from './v1ReplicaSetCondition';
export * from './v1ReplicaSetList';
export * from './v1ReplicaSetSpec';
export * from './v1ReplicaSetStatus';
export * from './v1ReplicationController';
export * from './v1ReplicationControllerCondition';
export * from './v1ReplicationControllerList';
export * from './v1ReplicationControllerSpec';
export * from './v1ReplicationControllerStatus';
export * from './v1ResourceAttributes';
export * from './v1ResourceFieldSelector';
export * from './v1ResourceQuota';
export * from './v1ResourceQuotaList';
export * from './v1ResourceQuotaSpec';
export * from './v1ResourceQuotaStatus';
export * from './v1ResourceRequirements';
export * from './v1ResourceRule';
export * from './v1Role';
export * from './v1RoleBinding';
export * from './v1RoleBindingList';
export * from './v1RoleList';
export * from './v1RoleRef';
export * from './v1RollingUpdateDaemonSet';
export * from './v1RollingUpdateDeployment';
export * from './v1RollingUpdateStatefulSetStrategy';
export * from './v1RuleWithOperations';
export * from './v1RuntimeClass';
export * from './v1RuntimeClassList';
export * from './v1SELinuxOptions';
export * from './v1Scale';
export * from './v1ScaleIOPersistentVolumeSource';
export * from './v1ScaleIOVolumeSource';
export * from './v1ScaleSpec';
export * from './v1ScaleStatus';
export * from './v1Scheduling';
export * from './v1ScopeSelector';
export * from './v1ScopedResourceSelectorRequirement';
export * from './v1SeccompProfile';
export * from './v1Secret';
export * from './v1SecretEnvSource';
export * from './v1SecretKeySelector';
export * from './v1SecretList';
export * from './v1SecretProjection';
export * from './v1SecretReference';
export * from './v1SecretVolumeSource';
export * from './v1SecurityContext';
export * from './v1SelfSubjectAccessReview';
export * from './v1SelfSubjectAccessReviewSpec';
export * from './v1SelfSubjectRulesReview';
export * from './v1SelfSubjectRulesReviewSpec';
export * from './v1ServerAddressByClientCIDR';
export * from './v1Service';
export * from './v1ServiceAccount';
export * from './v1ServiceAccountList';
export * from './v1ServiceAccountTokenProjection';
export * from './v1ServiceBackendPort';
export * from './v1ServiceList';
export * from './v1ServicePort';
export * from './v1ServiceSpec';
export * from './v1ServiceStatus';
export * from './v1SessionAffinityConfig';
export * from './v1StatefulSet';
export * from './v1StatefulSetCondition';
export * from './v1StatefulSetList';
export * from './v1StatefulSetSpec';
export * from './v1StatefulSetStatus';
export * from './v1StatefulSetUpdateStrategy';
export * from './v1Status';
export * from './v1StatusCause';
export * from './v1StatusDetails';
export * from './v1StorageClass';
export * from './v1StorageClassList';
export * from './v1StorageOSPersistentVolumeSource';
export * from './v1StorageOSVolumeSource';
export * from './v1Subject';
export * from './v1SubjectAccessReview';
export * from './v1SubjectAccessReviewSpec';
export * from './v1SubjectAccessReviewStatus';
export * from './v1SubjectRulesReviewStatus';
export * from './v1Sysctl';
export * from './v1TCPSocketAction';
export * from './v1Taint';
export * from './v1TokenRequestSpec';
export * from './v1TokenRequestStatus';
export * from './v1TokenReview';
export * from './v1TokenReviewSpec';
export * from './v1TokenReviewStatus';
export * from './v1Toleration';
export * from './v1TopologySelectorLabelRequirement';
export * from './v1TopologySelectorTerm';
export * from './v1TopologySpreadConstraint';
export * from './v1TypedLocalObjectReference';
export * from './v1UncountedTerminatedPods';
export * from './v1UserInfo';
export * from './v1ValidatingWebhook';
export * from './v1ValidatingWebhookConfiguration';
export * from './v1ValidatingWebhookConfigurationList';
export * from './v1Volume';
export * from './v1VolumeAttachment';
export * from './v1VolumeAttachmentList';
export * from './v1VolumeAttachmentSource';
export * from './v1VolumeAttachmentSpec';
export * from './v1VolumeAttachmentStatus';
export * from './v1VolumeDevice';
export * from './v1VolumeError';
export * from './v1VolumeMount';
export * from './v1VolumeNodeAffinity';
export * from './v1VolumeNodeResources';
export * from './v1VolumeProjection';
export * from './v1VsphereVirtualDiskVolumeSource';
export * from './v1WatchEvent';
export * from './v1WebhookConversion';
export * from './v1WeightedPodAffinityTerm';
export * from './v1WindowsSecurityContextOptions';
export * from './v1alpha1AggregationRule';
export * from './v1alpha1CSIStorageCapacity';
export * from './v1alpha1CSIStorageCapacityList';
export * from './v1alpha1ClusterRole';
export * from './v1alpha1ClusterRoleBinding';
export * from './v1alpha1ClusterRoleBindingList';
export * from './v1alpha1ClusterRoleList';
export * from './v1alpha1Overhead';
export * from './v1alpha1PolicyRule';
export * from './v1alpha1PriorityClass';
export * from './v1alpha1PriorityClassList';
export * from './v1alpha1Role';
export * from './v1alpha1RoleBinding';
export * from './v1alpha1RoleBindingList';
export * from './v1alpha1RoleList';
export * from './v1alpha1RoleRef';
export * from './v1alpha1RuntimeClass';
export * from './v1alpha1RuntimeClassList';
export * from './v1alpha1RuntimeClassSpec';
export * from './v1alpha1Scheduling';
export * from './v1alpha1ServerStorageVersion';
export * from './v1alpha1StorageVersion';
export * from './v1alpha1StorageVersionCondition';
export * from './v1alpha1StorageVersionList';
export * from './v1alpha1StorageVersionStatus';
export * from './v1alpha1Subject';
export * from './v1alpha1VolumeAttachment';
export * from './v1alpha1VolumeAttachmentList';
export * from './v1alpha1VolumeAttachmentSource';
export * from './v1alpha1VolumeAttachmentSpec';
export * from './v1alpha1VolumeAttachmentStatus';
export * from './v1alpha1VolumeError';
export * from './v1beta1AllowedCSIDriver';
export * from './v1beta1AllowedFlexVolume';
export * from './v1beta1AllowedHostPath';
export * from './v1beta1CSIStorageCapacity';
export * from './v1beta1CSIStorageCapacityList';
export * from './v1beta1CronJob';
export * from './v1beta1CronJobList';
export * from './v1beta1CronJobSpec';
export * from './v1beta1CronJobStatus';
export * from './v1beta1Endpoint';
export * from './v1beta1EndpointConditions';
export * from './v1beta1EndpointHints';
export * from './v1beta1EndpointPort';
export * from './v1beta1EndpointSlice';
export * from './v1beta1EndpointSliceList';
export * from './v1beta1Event';
export * from './v1beta1EventList';
export * from './v1beta1EventSeries';
export * from './v1beta1FSGroupStrategyOptions';
export * from './v1beta1FlowDistinguisherMethod';
export * from './v1beta1FlowSchema';
export * from './v1beta1FlowSchemaCondition';
export * from './v1beta1FlowSchemaList';
export * from './v1beta1FlowSchemaSpec';
export * from './v1beta1FlowSchemaStatus';
export * from './v1beta1ForZone';
export * from './v1beta1GroupSubject';
export * from './v1beta1HostPortRange';
export * from './v1beta1IDRange';
export * from './v1beta1JobTemplateSpec';
export * from './v1beta1LimitResponse';
export * from './v1beta1LimitedPriorityLevelConfiguration';
export * from './v1beta1NonResourcePolicyRule';
export * from './v1beta1Overhead';
export * from './v1beta1PodDisruptionBudget';
export * from './v1beta1PodDisruptionBudgetList';
export * from './v1beta1PodDisruptionBudgetSpec';
export * from './v1beta1PodDisruptionBudgetStatus';
export * from './v1beta1PodSecurityPolicy';
export * from './v1beta1PodSecurityPolicyList';
export * from './v1beta1PodSecurityPolicySpec';
export * from './v1beta1PolicyRulesWithSubjects';
export * from './v1beta1PriorityLevelConfiguration';
export * from './v1beta1PriorityLevelConfigurationCondition';
export * from './v1beta1PriorityLevelConfigurationList';
export * from './v1beta1PriorityLevelConfigurationReference';
export * from './v1beta1PriorityLevelConfigurationSpec';
export * from './v1beta1PriorityLevelConfigurationStatus';
export * from './v1beta1QueuingConfiguration';
export * from './v1beta1ResourcePolicyRule';
export * from './v1beta1RunAsGroupStrategyOptions';
export * from './v1beta1RunAsUserStrategyOptions';
export * from './v1beta1RuntimeClass';
export * from './v1beta1RuntimeClassList';
export * from './v1beta1RuntimeClassStrategyOptions';
export * from './v1beta1SELinuxStrategyOptions';
export * from './v1beta1Scheduling';
export * from './v1beta1ServiceAccountSubject';
export * from './v1beta1Subject';
export * from './v1beta1SupplementalGroupsStrategyOptions';
export * from './v1beta1UserSubject';
export * from './v2beta1ContainerResourceMetricSource';
export * from './v2beta1ContainerResourceMetricStatus';
export * from './v2beta1CrossVersionObjectReference';
export * from './v2beta1ExternalMetricSource';
export * from './v2beta1ExternalMetricStatus';
export * from './v2beta1HorizontalPodAutoscaler';
export * from './v2beta1HorizontalPodAutoscalerCondition';
export * from './v2beta1HorizontalPodAutoscalerList';
export * from './v2beta1HorizontalPodAutoscalerSpec';
export * from './v2beta1HorizontalPodAutoscalerStatus';
export * from './v2beta1MetricSpec';
export * from './v2beta1MetricStatus';
export * from './v2beta1ObjectMetricSource';
export * from './v2beta1ObjectMetricStatus';
export * from './v2beta1PodsMetricSource';
export * from './v2beta1PodsMetricStatus';
export * from './v2beta1ResourceMetricSource';
export * from './v2beta1ResourceMetricStatus';
export * from './v2beta2ContainerResourceMetricSource';
export * from './v2beta2ContainerResourceMetricStatus';
export * from './v2beta2CrossVersionObjectReference';
export * from './v2beta2ExternalMetricSource';
export * from './v2beta2ExternalMetricStatus';
export * from './v2beta2HPAScalingPolicy';
export * from './v2beta2HPAScalingRules';
export * from './v2beta2HorizontalPodAutoscaler';
export * from './v2beta2HorizontalPodAutoscalerBehavior';
export * from './v2beta2HorizontalPodAutoscalerCondition';
export * from './v2beta2HorizontalPodAutoscalerList';
export * from './v2beta2HorizontalPodAutoscalerSpec';
export * from './v2beta2HorizontalPodAutoscalerStatus';
export * from './v2beta2MetricIdentifier';
export * from './v2beta2MetricSpec';
export * from './v2beta2MetricStatus';
export * from './v2beta2MetricTarget';
export * from './v2beta2MetricValueStatus';
export * from './v2beta2ObjectMetricSource';
export * from './v2beta2ObjectMetricStatus';
export * from './v2beta2PodsMetricSource';
export * from './v2beta2PodsMetricStatus';
export * from './v2beta2ResourceMetricSource';
export * from './v2beta2ResourceMetricStatus';
export * from './versionInfo';

import * as fs from 'fs';

export interface RequestDetailedFile {
  value: Buffer;
  options?: {
    filename?: string;
    contentType?: string;
  };
}

export type RequestFile = string | Buffer | fs.ReadStream | RequestDetailedFile;

import { AdmissionregistrationV1ServiceReference } from './admissionregistrationV1ServiceReference';
import { AdmissionregistrationV1WebhookClientConfig } from './admissionregistrationV1WebhookClientConfig';
import { ApiextensionsV1ServiceReference } from './apiextensionsV1ServiceReference';
import { ApiextensionsV1WebhookClientConfig } from './apiextensionsV1WebhookClientConfig';
import { ApiregistrationV1ServiceReference } from './apiregistrationV1ServiceReference';
import { AuthenticationV1TokenRequest } from './authenticationV1TokenRequest';
import { CoreV1EndpointPort } from './coreV1EndpointPort';
import { CoreV1Event } from './coreV1Event';
import { CoreV1EventList } from './coreV1EventList';
import { CoreV1EventSeries } from './coreV1EventSeries';
import { DiscoveryV1EndpointPort } from './discoveryV1EndpointPort';
import { EventsV1Event } from './eventsV1Event';
import { EventsV1EventList } from './eventsV1EventList';
import { EventsV1EventSeries } from './eventsV1EventSeries';
import { StorageV1TokenRequest } from './storageV1TokenRequest';
import { V1APIGroup } from './v1APIGroup';
import { V1APIGroupList } from './v1APIGroupList';
import { V1APIResource } from './v1APIResource';
import { V1APIResourceList } from './v1APIResourceList';
import { V1APIService } from './v1APIService';
import { V1APIServiceCondition } from './v1APIServiceCondition';
import { V1APIServiceList } from './v1APIServiceList';
import { V1APIServiceSpec } from './v1APIServiceSpec';
import { V1APIServiceStatus } from './v1APIServiceStatus';
import { V1APIVersions } from './v1APIVersions';
import { V1AWSElasticBlockStoreVolumeSource } from './v1AWSElasticBlockStoreVolumeSource';
import { V1Affinity } from './v1Affinity';
import { V1AggregationRule } from './v1AggregationRule';
import { V1AttachedVolume } from './v1AttachedVolume';
import { V1AzureDiskVolumeSource } from './v1AzureDiskVolumeSource';
import { V1AzureFilePersistentVolumeSource } from './v1AzureFilePersistentVolumeSource';
import { V1AzureFileVolumeSource } from './v1AzureFileVolumeSource';
import { V1Binding } from './v1Binding';
import { V1BoundObjectReference } from './v1BoundObjectReference';
import { V1CSIDriver } from './v1CSIDriver';
import { V1CSIDriverList } from './v1CSIDriverList';
import { V1CSIDriverSpec } from './v1CSIDriverSpec';
import { V1CSINode } from './v1CSINode';
import { V1CSINodeDriver } from './v1CSINodeDriver';
import { V1CSINodeList } from './v1CSINodeList';
import { V1CSINodeSpec } from './v1CSINodeSpec';
import { V1CSIPersistentVolumeSource } from './v1CSIPersistentVolumeSource';
import { V1CSIVolumeSource } from './v1CSIVolumeSource';
import { V1Capabilities } from './v1Capabilities';
import { V1CephFSPersistentVolumeSource } from './v1CephFSPersistentVolumeSource';
import { V1CephFSVolumeSource } from './v1CephFSVolumeSource';
import { V1CertificateSigningRequest } from './v1CertificateSigningRequest';
import { V1CertificateSigningRequestCondition } from './v1CertificateSigningRequestCondition';
import { V1CertificateSigningRequestList } from './v1CertificateSigningRequestList';
import { V1CertificateSigningRequestSpec } from './v1CertificateSigningRequestSpec';
import { V1CertificateSigningRequestStatus } from './v1CertificateSigningRequestStatus';
import { V1CinderPersistentVolumeSource } from './v1CinderPersistentVolumeSource';
import { V1CinderVolumeSource } from './v1CinderVolumeSource';
import { V1ClientIPConfig } from './v1ClientIPConfig';
import { V1ClusterRole } from './v1ClusterRole';
import { V1ClusterRoleBinding } from './v1ClusterRoleBinding';
import { V1ClusterRoleBindingList } from './v1ClusterRoleBindingList';
import { V1ClusterRoleList } from './v1ClusterRoleList';
import { V1ComponentCondition } from './v1ComponentCondition';
import { V1ComponentStatus } from './v1ComponentStatus';
import { V1ComponentStatusList } from './v1ComponentStatusList';
import { V1Condition } from './v1Condition';
import { V1ConfigMap } from './v1ConfigMap';
import { V1ConfigMapEnvSource } from './v1ConfigMapEnvSource';
import { V1ConfigMapKeySelector } from './v1ConfigMapKeySelector';
import { V1ConfigMapList } from './v1ConfigMapList';
import { V1ConfigMapNodeConfigSource } from './v1ConfigMapNodeConfigSource';
import { V1ConfigMapProjection } from './v1ConfigMapProjection';
import { V1ConfigMapVolumeSource } from './v1ConfigMapVolumeSource';
import { V1Container } from './v1Container';
import { V1ContainerImage } from './v1ContainerImage';
import { V1ContainerPort } from './v1ContainerPort';
import { V1ContainerState } from './v1ContainerState';
import { V1ContainerStateRunning } from './v1ContainerStateRunning';
import { V1ContainerStateTerminated } from './v1ContainerStateTerminated';
import { V1ContainerStateWaiting } from './v1ContainerStateWaiting';
import { V1ContainerStatus } from './v1ContainerStatus';
import { V1ControllerRevision } from './v1ControllerRevision';
import { V1ControllerRevisionList } from './v1ControllerRevisionList';
import { V1CronJob } from './v1CronJob';
import { V1CronJobList } from './v1CronJobList';
import { V1CronJobSpec } from './v1CronJobSpec';
import { V1CronJobStatus } from './v1CronJobStatus';
import { V1CrossVersionObjectReference } from './v1CrossVersionObjectReference';
import { V1CustomResourceColumnDefinition } from './v1CustomResourceColumnDefinition';
import { V1CustomResourceConversion } from './v1CustomResourceConversion';
import { V1CustomResourceDefinition } from './v1CustomResourceDefinition';
import { V1CustomResourceDefinitionCondition } from './v1CustomResourceDefinitionCondition';
import { V1CustomResourceDefinitionList } from './v1CustomResourceDefinitionList';
import { V1CustomResourceDefinitionNames } from './v1CustomResourceDefinitionNames';
import { V1CustomResourceDefinitionSpec } from './v1CustomResourceDefinitionSpec';
import { V1CustomResourceDefinitionStatus } from './v1CustomResourceDefinitionStatus';
import { V1CustomResourceDefinitionVersion } from './v1CustomResourceDefinitionVersion';
import { V1CustomResourceSubresourceScale } from './v1CustomResourceSubresourceScale';
import { V1CustomResourceSubresources } from './v1CustomResourceSubresources';
import { V1CustomResourceValidation } from './v1CustomResourceValidation';
import { V1DaemonEndpoint } from './v1DaemonEndpoint';
import { V1DaemonSet } from './v1DaemonSet';
import { V1DaemonSetCondition } from './v1DaemonSetCondition';
import { V1DaemonSetList } from './v1DaemonSetList';
import { V1DaemonSetSpec } from './v1DaemonSetSpec';
import { V1DaemonSetStatus } from './v1DaemonSetStatus';
import { V1DaemonSetUpdateStrategy } from './v1DaemonSetUpdateStrategy';
import { V1DeleteOptions } from './v1DeleteOptions';
import { V1Deployment } from './v1Deployment';
import { V1DeploymentCondition } from './v1DeploymentCondition';
import { V1DeploymentList } from './v1DeploymentList';
import { V1DeploymentSpec } from './v1DeploymentSpec';
import { V1DeploymentStatus } from './v1DeploymentStatus';
import { V1DeploymentStrategy } from './v1DeploymentStrategy';
import { V1DownwardAPIProjection } from './v1DownwardAPIProjection';
import { V1DownwardAPIVolumeFile } from './v1DownwardAPIVolumeFile';
import { V1DownwardAPIVolumeSource } from './v1DownwardAPIVolumeSource';
import { V1EmptyDirVolumeSource } from './v1EmptyDirVolumeSource';
import { V1Endpoint } from './v1Endpoint';
import { V1EndpointAddress } from './v1EndpointAddress';
import { V1EndpointConditions } from './v1EndpointConditions';
import { V1EndpointHints } from './v1EndpointHints';
import { V1EndpointSlice } from './v1EndpointSlice';
import { V1EndpointSliceList } from './v1EndpointSliceList';
import { V1EndpointSubset } from './v1EndpointSubset';
import { V1Endpoints } from './v1Endpoints';
import { V1EndpointsList } from './v1EndpointsList';
import { V1EnvFromSource } from './v1EnvFromSource';
import { V1EnvVar } from './v1EnvVar';
import { V1EnvVarSource } from './v1EnvVarSource';
import { V1EphemeralContainer } from './v1EphemeralContainer';
import { V1EphemeralVolumeSource } from './v1EphemeralVolumeSource';
import { V1EventSource } from './v1EventSource';
import { V1Eviction } from './v1Eviction';
import { V1ExecAction } from './v1ExecAction';
import { V1ExternalDocumentation } from './v1ExternalDocumentation';
import { V1FCVolumeSource } from './v1FCVolumeSource';
import { V1FlexPersistentVolumeSource } from './v1FlexPersistentVolumeSource';
import { V1FlexVolumeSource } from './v1FlexVolumeSource';
import { V1FlockerVolumeSource } from './v1FlockerVolumeSource';
import { V1ForZone } from './v1ForZone';
import { V1GCEPersistentDiskVolumeSource } from './v1GCEPersistentDiskVolumeSource';
import { V1GitRepoVolumeSource } from './v1GitRepoVolumeSource';
import { V1GlusterfsPersistentVolumeSource } from './v1GlusterfsPersistentVolumeSource';
import { V1GlusterfsVolumeSource } from './v1GlusterfsVolumeSource';
import { V1GroupVersionForDiscovery } from './v1GroupVersionForDiscovery';
import { V1HTTPGetAction } from './v1HTTPGetAction';
import { V1HTTPHeader } from './v1HTTPHeader';
import { V1HTTPIngressPath } from './v1HTTPIngressPath';
import { V1HTTPIngressRuleValue } from './v1HTTPIngressRuleValue';
import { V1Handler } from './v1Handler';
import { V1HorizontalPodAutoscaler } from './v1HorizontalPodAutoscaler';
import { V1HorizontalPodAutoscalerList } from './v1HorizontalPodAutoscalerList';
import { V1HorizontalPodAutoscalerSpec } from './v1HorizontalPodAutoscalerSpec';
import { V1HorizontalPodAutoscalerStatus } from './v1HorizontalPodAutoscalerStatus';
import { V1HostAlias } from './v1HostAlias';
import { V1HostPathVolumeSource } from './v1HostPathVolumeSource';
import { V1IPBlock } from './v1IPBlock';
import { V1ISCSIPersistentVolumeSource } from './v1ISCSIPersistentVolumeSource';
import { V1ISCSIVolumeSource } from './v1ISCSIVolumeSource';
import { V1Ingress } from './v1Ingress';
import { V1IngressBackend } from './v1IngressBackend';
import { V1IngressClass } from './v1IngressClass';
import { V1IngressClassList } from './v1IngressClassList';
import { V1IngressClassParametersReference } from './v1IngressClassParametersReference';
import { V1IngressClassSpec } from './v1IngressClassSpec';
import { V1IngressList } from './v1IngressList';
import { V1IngressRule } from './v1IngressRule';
import { V1IngressServiceBackend } from './v1IngressServiceBackend';
import { V1IngressSpec } from './v1IngressSpec';
import { V1IngressStatus } from './v1IngressStatus';
import { V1IngressTLS } from './v1IngressTLS';
import { V1JSONSchemaProps } from './v1JSONSchemaProps';
import { V1Job } from './v1Job';
import { V1JobCondition } from './v1JobCondition';
import { V1JobList } from './v1JobList';
import { V1JobSpec } from './v1JobSpec';
import { V1JobStatus } from './v1JobStatus';
import { V1JobTemplateSpec } from './v1JobTemplateSpec';
import { V1KeyToPath } from './v1KeyToPath';
import { V1LabelSelector } from './v1LabelSelector';
import { V1LabelSelectorRequirement } from './v1LabelSelectorRequirement';
import { V1Lease } from './v1Lease';
import { V1LeaseList } from './v1LeaseList';
import { V1LeaseSpec } from './v1LeaseSpec';
import { V1Lifecycle } from './v1Lifecycle';
import { V1LimitRange } from './v1LimitRange';
import { V1LimitRangeItem } from './v1LimitRangeItem';
import { V1LimitRangeList } from './v1LimitRangeList';
import { V1LimitRangeSpec } from './v1LimitRangeSpec';
import { V1ListMeta } from './v1ListMeta';
import { V1LoadBalancerIngress } from './v1LoadBalancerIngress';
import { V1LoadBalancerStatus } from './v1LoadBalancerStatus';
import { V1LocalObjectReference } from './v1LocalObjectReference';
import { V1LocalSubjectAccessReview } from './v1LocalSubjectAccessReview';
import { V1LocalVolumeSource } from './v1LocalVolumeSource';
import { V1ManagedFieldsEntry } from './v1ManagedFieldsEntry';
import { V1MutatingWebhook } from './v1MutatingWebhook';
import { V1MutatingWebhookConfiguration } from './v1MutatingWebhookConfiguration';
import { V1MutatingWebhookConfigurationList } from './v1MutatingWebhookConfigurationList';
import { V1NFSVolumeSource } from './v1NFSVolumeSource';
import { V1Namespace } from './v1Namespace';
import { V1NamespaceCondition } from './v1NamespaceCondition';
import { V1NamespaceList } from './v1NamespaceList';
import { V1NamespaceSpec } from './v1NamespaceSpec';
import { V1NamespaceStatus } from './v1NamespaceStatus';
import { V1NetworkPolicy } from './v1NetworkPolicy';
import { V1NetworkPolicyEgressRule } from './v1NetworkPolicyEgressRule';
import { V1NetworkPolicyIngressRule } from './v1NetworkPolicyIngressRule';
import { V1NetworkPolicyList } from './v1NetworkPolicyList';
import { V1NetworkPolicyPeer } from './v1NetworkPolicyPeer';
import { V1NetworkPolicyPort } from './v1NetworkPolicyPort';
import { V1NetworkPolicySpec } from './v1NetworkPolicySpec';
import { V1Node } from './v1Node';
import { V1NodeAddress } from './v1NodeAddress';
import { V1NodeAffinity } from './v1NodeAffinity';
import { V1NodeCondition } from './v1NodeCondition';
import { V1NodeConfigSource } from './v1NodeConfigSource';
import { V1NodeConfigStatus } from './v1NodeConfigStatus';
import { V1NodeDaemonEndpoints } from './v1NodeDaemonEndpoints';
import { V1NodeList } from './v1NodeList';
import { V1NodeSelector } from './v1NodeSelector';
import { V1NodeSelectorRequirement } from './v1NodeSelectorRequirement';
import { V1NodeSelectorTerm } from './v1NodeSelectorTerm';
import { V1NodeSpec } from './v1NodeSpec';
import { V1NodeStatus } from './v1NodeStatus';
import { V1NodeSystemInfo } from './v1NodeSystemInfo';
import { V1NonResourceAttributes } from './v1NonResourceAttributes';
import { V1NonResourceRule } from './v1NonResourceRule';
import { V1ObjectFieldSelector } from './v1ObjectFieldSelector';
import { V1ObjectMeta } from './v1ObjectMeta';
import { V1ObjectReference } from './v1ObjectReference';
import { V1Overhead } from './v1Overhead';
import { V1OwnerReference } from './v1OwnerReference';
import { V1PersistentVolume } from './v1PersistentVolume';
import { V1PersistentVolumeClaim } from './v1PersistentVolumeClaim';
import { V1PersistentVolumeClaimCondition } from './v1PersistentVolumeClaimCondition';
import { V1PersistentVolumeClaimList } from './v1PersistentVolumeClaimList';
import { V1PersistentVolumeClaimSpec } from './v1PersistentVolumeClaimSpec';
import { V1PersistentVolumeClaimStatus } from './v1PersistentVolumeClaimStatus';
import { V1PersistentVolumeClaimTemplate } from './v1PersistentVolumeClaimTemplate';
import { V1PersistentVolumeClaimVolumeSource } from './v1PersistentVolumeClaimVolumeSource';
import { V1PersistentVolumeList } from './v1PersistentVolumeList';
import { V1PersistentVolumeSpec } from './v1PersistentVolumeSpec';
import { V1PersistentVolumeStatus } from './v1PersistentVolumeStatus';
import { V1PhotonPersistentDiskVolumeSource } from './v1PhotonPersistentDiskVolumeSource';
import { V1Pod } from './v1Pod';
import { V1PodAffinity } from './v1PodAffinity';
import { V1PodAffinityTerm } from './v1PodAffinityTerm';
import { V1PodAntiAffinity } from './v1PodAntiAffinity';
import { V1PodCondition } from './v1PodCondition';
import { V1PodDNSConfig } from './v1PodDNSConfig';
import { V1PodDNSConfigOption } from './v1PodDNSConfigOption';
import { V1PodDisruptionBudget } from './v1PodDisruptionBudget';
import { V1PodDisruptionBudgetList } from './v1PodDisruptionBudgetList';
import { V1PodDisruptionBudgetSpec } from './v1PodDisruptionBudgetSpec';
import { V1PodDisruptionBudgetStatus } from './v1PodDisruptionBudgetStatus';
import { V1PodIP } from './v1PodIP';
import { V1PodList } from './v1PodList';
import { V1PodReadinessGate } from './v1PodReadinessGate';
import { V1PodSecurityContext } from './v1PodSecurityContext';
import { V1PodSpec } from './v1PodSpec';
import { V1PodStatus } from './v1PodStatus';
import { V1PodTemplate } from './v1PodTemplate';
import { V1PodTemplateList } from './v1PodTemplateList';
import { V1PodTemplateSpec } from './v1PodTemplateSpec';
import { V1PolicyRule } from './v1PolicyRule';
import { V1PortStatus } from './v1PortStatus';
import { V1PortworxVolumeSource } from './v1PortworxVolumeSource';
import { V1Preconditions } from './v1Preconditions';
import { V1PreferredSchedulingTerm } from './v1PreferredSchedulingTerm';
import { V1PriorityClass } from './v1PriorityClass';
import { V1PriorityClassList } from './v1PriorityClassList';
import { V1Probe } from './v1Probe';
import { V1ProjectedVolumeSource } from './v1ProjectedVolumeSource';
import { V1QuobyteVolumeSource } from './v1QuobyteVolumeSource';
import { V1RBDPersistentVolumeSource } from './v1RBDPersistentVolumeSource';
import { V1RBDVolumeSource } from './v1RBDVolumeSource';
import { V1ReplicaSet } from './v1ReplicaSet';
import { V1ReplicaSetCondition } from './v1ReplicaSetCondition';
import { V1ReplicaSetList } from './v1ReplicaSetList';
import { V1ReplicaSetSpec } from './v1ReplicaSetSpec';
import { V1ReplicaSetStatus } from './v1ReplicaSetStatus';
import { V1ReplicationController } from './v1ReplicationController';
import { V1ReplicationControllerCondition } from './v1ReplicationControllerCondition';
import { V1ReplicationControllerList } from './v1ReplicationControllerList';
import { V1ReplicationControllerSpec } from './v1ReplicationControllerSpec';
import { V1ReplicationControllerStatus } from './v1ReplicationControllerStatus';
import { V1ResourceAttributes } from './v1ResourceAttributes';
import { V1ResourceFieldSelector } from './v1ResourceFieldSelector';
import { V1ResourceQuota } from './v1ResourceQuota';
import { V1ResourceQuotaList } from './v1ResourceQuotaList';
import { V1ResourceQuotaSpec } from './v1ResourceQuotaSpec';
import { V1ResourceQuotaStatus } from './v1ResourceQuotaStatus';
import { V1ResourceRequirements } from './v1ResourceRequirements';
import { V1ResourceRule } from './v1ResourceRule';
import { V1Role } from './v1Role';
import { V1RoleBinding } from './v1RoleBinding';
import { V1RoleBindingList } from './v1RoleBindingList';
import { V1RoleList } from './v1RoleList';
import { V1RoleRef } from './v1RoleRef';
import { V1RollingUpdateDaemonSet } from './v1RollingUpdateDaemonSet';
import { V1RollingUpdateDeployment } from './v1RollingUpdateDeployment';
import { V1RollingUpdateStatefulSetStrategy } from './v1RollingUpdateStatefulSetStrategy';
import { V1RuleWithOperations } from './v1RuleWithOperations';
import { V1RuntimeClass } from './v1RuntimeClass';
import { V1RuntimeClassList } from './v1RuntimeClassList';
import { V1SELinuxOptions } from './v1SELinuxOptions';
import { V1Scale } from './v1Scale';
import { V1ScaleIOPersistentVolumeSource } from './v1ScaleIOPersistentVolumeSource';
import { V1ScaleIOVolumeSource } from './v1ScaleIOVolumeSource';
import { V1ScaleSpec } from './v1ScaleSpec';
import { V1ScaleStatus } from './v1ScaleStatus';
import { V1Scheduling } from './v1Scheduling';
import { V1ScopeSelector } from './v1ScopeSelector';
import { V1ScopedResourceSelectorRequirement } from './v1ScopedResourceSelectorRequirement';
import { V1SeccompProfile } from './v1SeccompProfile';
import { V1Secret } from './v1Secret';
import { V1SecretEnvSource } from './v1SecretEnvSource';
import { V1SecretKeySelector } from './v1SecretKeySelector';
import { V1SecretList } from './v1SecretList';
import { V1SecretProjection } from './v1SecretProjection';
import { V1SecretReference } from './v1SecretReference';
import { V1SecretVolumeSource } from './v1SecretVolumeSource';
import { V1SecurityContext } from './v1SecurityContext';
import { V1SelfSubjectAccessReview } from './v1SelfSubjectAccessReview';
import { V1SelfSubjectAccessReviewSpec } from './v1SelfSubjectAccessReviewSpec';
import { V1SelfSubjectRulesReview } from './v1SelfSubjectRulesReview';
import { V1SelfSubjectRulesReviewSpec } from './v1SelfSubjectRulesReviewSpec';
import { V1ServerAddressByClientCIDR } from './v1ServerAddressByClientCIDR';
import { V1Service } from './v1Service';
import { V1ServiceAccount } from './v1ServiceAccount';
import { V1ServiceAccountList } from './v1ServiceAccountList';
import { V1ServiceAccountTokenProjection } from './v1ServiceAccountTokenProjection';
import { V1ServiceBackendPort } from './v1ServiceBackendPort';
import { V1ServiceList } from './v1ServiceList';
import { V1ServicePort } from './v1ServicePort';
import { V1ServiceSpec } from './v1ServiceSpec';
import { V1ServiceStatus } from './v1ServiceStatus';
import { V1SessionAffinityConfig } from './v1SessionAffinityConfig';
import { V1StatefulSet } from './v1StatefulSet';
import { V1StatefulSetCondition } from './v1StatefulSetCondition';
import { V1StatefulSetList } from './v1StatefulSetList';
import { V1StatefulSetSpec } from './v1StatefulSetSpec';
import { V1StatefulSetStatus } from './v1StatefulSetStatus';
import { V1StatefulSetUpdateStrategy } from './v1StatefulSetUpdateStrategy';
import { V1Status } from './v1Status';
import { V1StatusCause } from './v1StatusCause';
import { V1StatusDetails } from './v1StatusDetails';
import { V1StorageClass } from './v1StorageClass';
import { V1StorageClassList } from './v1StorageClassList';
import { V1StorageOSPersistentVolumeSource } from './v1StorageOSPersistentVolumeSource';
import { V1StorageOSVolumeSource } from './v1StorageOSVolumeSource';
import { V1Subject } from './v1Subject';
import { V1SubjectAccessReview } from './v1SubjectAccessReview';
import { V1SubjectAccessReviewSpec } from './v1SubjectAccessReviewSpec';
import { V1SubjectAccessReviewStatus } from './v1SubjectAccessReviewStatus';
import { V1SubjectRulesReviewStatus } from './v1SubjectRulesReviewStatus';
import { V1Sysctl } from './v1Sysctl';
import { V1TCPSocketAction } from './v1TCPSocketAction';
import { V1Taint } from './v1Taint';
import { V1TokenRequestSpec } from './v1TokenRequestSpec';
import { V1TokenRequestStatus } from './v1TokenRequestStatus';
import { V1TokenReview } from './v1TokenReview';
import { V1TokenReviewSpec } from './v1TokenReviewSpec';
import { V1TokenReviewStatus } from './v1TokenReviewStatus';
import { V1Toleration } from './v1Toleration';
import { V1TopologySelectorLabelRequirement } from './v1TopologySelectorLabelRequirement';
import { V1TopologySelectorTerm } from './v1TopologySelectorTerm';
import { V1TopologySpreadConstraint } from './v1TopologySpreadConstraint';
import { V1TypedLocalObjectReference } from './v1TypedLocalObjectReference';
import { V1UncountedTerminatedPods } from './v1UncountedTerminatedPods';
import { V1UserInfo } from './v1UserInfo';
import { V1ValidatingWebhook } from './v1ValidatingWebhook';
import { V1ValidatingWebhookConfiguration } from './v1ValidatingWebhookConfiguration';
import { V1ValidatingWebhookConfigurationList } from './v1ValidatingWebhookConfigurationList';
import { V1Volume } from './v1Volume';
import { V1VolumeAttachment } from './v1VolumeAttachment';
import { V1VolumeAttachmentList } from './v1VolumeAttachmentList';
import { V1VolumeAttachmentSource } from './v1VolumeAttachmentSource';
import { V1VolumeAttachmentSpec } from './v1VolumeAttachmentSpec';
import { V1VolumeAttachmentStatus } from './v1VolumeAttachmentStatus';
import { V1VolumeDevice } from './v1VolumeDevice';
import { V1VolumeError } from './v1VolumeError';
import { V1VolumeMount } from './v1VolumeMount';
import { V1VolumeNodeAffinity } from './v1VolumeNodeAffinity';
import { V1VolumeNodeResources } from './v1VolumeNodeResources';
import { V1VolumeProjection } from './v1VolumeProjection';
import { V1VsphereVirtualDiskVolumeSource } from './v1VsphereVirtualDiskVolumeSource';
import { V1WatchEvent } from './v1WatchEvent';
import { V1WebhookConversion } from './v1WebhookConversion';
import { V1WeightedPodAffinityTerm } from './v1WeightedPodAffinityTerm';
import { V1WindowsSecurityContextOptions } from './v1WindowsSecurityContextOptions';
import { V1alpha1AggregationRule } from './v1alpha1AggregationRule';
import { V1alpha1CSIStorageCapacity } from './v1alpha1CSIStorageCapacity';
import { V1alpha1CSIStorageCapacityList } from './v1alpha1CSIStorageCapacityList';
import { V1alpha1ClusterRole } from './v1alpha1ClusterRole';
import { V1alpha1ClusterRoleBinding } from './v1alpha1ClusterRoleBinding';
import { V1alpha1ClusterRoleBindingList } from './v1alpha1ClusterRoleBindingList';
import { V1alpha1ClusterRoleList } from './v1alpha1ClusterRoleList';
import { V1alpha1Overhead } from './v1alpha1Overhead';
import { V1alpha1PolicyRule } from './v1alpha1PolicyRule';
import { V1alpha1PriorityClass } from './v1alpha1PriorityClass';
import { V1alpha1PriorityClassList } from './v1alpha1PriorityClassList';
import { V1alpha1Role } from './v1alpha1Role';
import { V1alpha1RoleBinding } from './v1alpha1RoleBinding';
import { V1alpha1RoleBindingList } from './v1alpha1RoleBindingList';
import { V1alpha1RoleList } from './v1alpha1RoleList';
import { V1alpha1RoleRef } from './v1alpha1RoleRef';
import { V1alpha1RuntimeClass } from './v1alpha1RuntimeClass';
import { V1alpha1RuntimeClassList } from './v1alpha1RuntimeClassList';
import { V1alpha1RuntimeClassSpec } from './v1alpha1RuntimeClassSpec';
import { V1alpha1Scheduling } from './v1alpha1Scheduling';
import { V1alpha1ServerStorageVersion } from './v1alpha1ServerStorageVersion';
import { V1alpha1StorageVersion } from './v1alpha1StorageVersion';
import { V1alpha1StorageVersionCondition } from './v1alpha1StorageVersionCondition';
import { V1alpha1StorageVersionList } from './v1alpha1StorageVersionList';
import { V1alpha1StorageVersionStatus } from './v1alpha1StorageVersionStatus';
import { V1alpha1Subject } from './v1alpha1Subject';
import { V1alpha1VolumeAttachment } from './v1alpha1VolumeAttachment';
import { V1alpha1VolumeAttachmentList } from './v1alpha1VolumeAttachmentList';
import { V1alpha1VolumeAttachmentSource } from './v1alpha1VolumeAttachmentSource';
import { V1alpha1VolumeAttachmentSpec } from './v1alpha1VolumeAttachmentSpec';
import { V1alpha1VolumeAttachmentStatus } from './v1alpha1VolumeAttachmentStatus';
import { V1alpha1VolumeError } from './v1alpha1VolumeError';
import { V1beta1AllowedCSIDriver } from './v1beta1AllowedCSIDriver';
import { V1beta1AllowedFlexVolume } from './v1beta1AllowedFlexVolume';
import { V1beta1AllowedHostPath } from './v1beta1AllowedHostPath';
import { V1beta1CSIStorageCapacity } from './v1beta1CSIStorageCapacity';
import { V1beta1CSIStorageCapacityList } from './v1beta1CSIStorageCapacityList';
import { V1beta1CronJob } from './v1beta1CronJob';
import { V1beta1CronJobList } from './v1beta1CronJobList';
import { V1beta1CronJobSpec } from './v1beta1CronJobSpec';
import { V1beta1CronJobStatus } from './v1beta1CronJobStatus';
import { V1beta1Endpoint } from './v1beta1Endpoint';
import { V1beta1EndpointConditions } from './v1beta1EndpointConditions';
import { V1beta1EndpointHints } from './v1beta1EndpointHints';
import { V1beta1EndpointPort } from './v1beta1EndpointPort';
import { V1beta1EndpointSlice } from './v1beta1EndpointSlice';
import { V1beta1EndpointSliceList } from './v1beta1EndpointSliceList';
import { V1beta1Event } from './v1beta1Event';
import { V1beta1EventList } from './v1beta1EventList';
import { V1beta1EventSeries } from './v1beta1EventSeries';
import { V1beta1FSGroupStrategyOptions } from './v1beta1FSGroupStrategyOptions';
import { V1beta1FlowDistinguisherMethod } from './v1beta1FlowDistinguisherMethod';
import { V1beta1FlowSchema } from './v1beta1FlowSchema';
import { V1beta1FlowSchemaCondition } from './v1beta1FlowSchemaCondition';
import { V1beta1FlowSchemaList } from './v1beta1FlowSchemaList';
import { V1beta1FlowSchemaSpec } from './v1beta1FlowSchemaSpec';
import { V1beta1FlowSchemaStatus } from './v1beta1FlowSchemaStatus';
import { V1beta1ForZone } from './v1beta1ForZone';
import { V1beta1GroupSubject } from './v1beta1GroupSubject';
import { V1beta1HostPortRange } from './v1beta1HostPortRange';
import { V1beta1IDRange } from './v1beta1IDRange';
import { V1beta1JobTemplateSpec } from './v1beta1JobTemplateSpec';
import { V1beta1LimitResponse } from './v1beta1LimitResponse';
import { V1beta1LimitedPriorityLevelConfiguration } from './v1beta1LimitedPriorityLevelConfiguration';
import { V1beta1NonResourcePolicyRule } from './v1beta1NonResourcePolicyRule';
import { V1beta1Overhead } from './v1beta1Overhead';
import { V1beta1PodDisruptionBudget } from './v1beta1PodDisruptionBudget';
import { V1beta1PodDisruptionBudgetList } from './v1beta1PodDisruptionBudgetList';
import { V1beta1PodDisruptionBudgetSpec } from './v1beta1PodDisruptionBudgetSpec';
import { V1beta1PodDisruptionBudgetStatus } from './v1beta1PodDisruptionBudgetStatus';
import { V1beta1PodSecurityPolicy } from './v1beta1PodSecurityPolicy';
import { V1beta1PodSecurityPolicyList } from './v1beta1PodSecurityPolicyList';
import { V1beta1PodSecurityPolicySpec } from './v1beta1PodSecurityPolicySpec';
import { V1beta1PolicyRulesWithSubjects } from './v1beta1PolicyRulesWithSubjects';
import { V1beta1PriorityLevelConfiguration } from './v1beta1PriorityLevelConfiguration';
import { V1beta1PriorityLevelConfigurationCondition } from './v1beta1PriorityLevelConfigurationCondition';
import { V1beta1PriorityLevelConfigurationList } from './v1beta1PriorityLevelConfigurationList';
import { V1beta1PriorityLevelConfigurationReference } from './v1beta1PriorityLevelConfigurationReference';
import { V1beta1PriorityLevelConfigurationSpec } from './v1beta1PriorityLevelConfigurationSpec';
import { V1beta1PriorityLevelConfigurationStatus } from './v1beta1PriorityLevelConfigurationStatus';
import { V1beta1QueuingConfiguration } from './v1beta1QueuingConfiguration';
import { V1beta1ResourcePolicyRule } from './v1beta1ResourcePolicyRule';
import { V1beta1RunAsGroupStrategyOptions } from './v1beta1RunAsGroupStrategyOptions';
import { V1beta1RunAsUserStrategyOptions } from './v1beta1RunAsUserStrategyOptions';
import { V1beta1RuntimeClass } from './v1beta1RuntimeClass';
import { V1beta1RuntimeClassList } from './v1beta1RuntimeClassList';
import { V1beta1RuntimeClassStrategyOptions } from './v1beta1RuntimeClassStrategyOptions';
import { V1beta1SELinuxStrategyOptions } from './v1beta1SELinuxStrategyOptions';
import { V1beta1Scheduling } from './v1beta1Scheduling';
import { V1beta1ServiceAccountSubject } from './v1beta1ServiceAccountSubject';
import { V1beta1Subject } from './v1beta1Subject';
import { V1beta1SupplementalGroupsStrategyOptions } from './v1beta1SupplementalGroupsStrategyOptions';
import { V1beta1UserSubject } from './v1beta1UserSubject';
import { V2beta1ContainerResourceMetricSource } from './v2beta1ContainerResourceMetricSource';
import { V2beta1ContainerResourceMetricStatus } from './v2beta1ContainerResourceMetricStatus';
import { V2beta1CrossVersionObjectReference } from './v2beta1CrossVersionObjectReference';
import { V2beta1ExternalMetricSource } from './v2beta1ExternalMetricSource';
import { V2beta1ExternalMetricStatus } from './v2beta1ExternalMetricStatus';
import { V2beta1HorizontalPodAutoscaler } from './v2beta1HorizontalPodAutoscaler';
import { V2beta1HorizontalPodAutoscalerCondition } from './v2beta1HorizontalPodAutoscalerCondition';
import { V2beta1HorizontalPodAutoscalerList } from './v2beta1HorizontalPodAutoscalerList';
import { V2beta1HorizontalPodAutoscalerSpec } from './v2beta1HorizontalPodAutoscalerSpec';
import { V2beta1HorizontalPodAutoscalerStatus } from './v2beta1HorizontalPodAutoscalerStatus';
import { V2beta1MetricSpec } from './v2beta1MetricSpec';
import { V2beta1MetricStatus } from './v2beta1MetricStatus';
import { V2beta1ObjectMetricSource } from './v2beta1ObjectMetricSource';
import { V2beta1ObjectMetricStatus } from './v2beta1ObjectMetricStatus';
import { V2beta1PodsMetricSource } from './v2beta1PodsMetricSource';
import { V2beta1PodsMetricStatus } from './v2beta1PodsMetricStatus';
import { V2beta1ResourceMetricSource } from './v2beta1ResourceMetricSource';
import { V2beta1ResourceMetricStatus } from './v2beta1ResourceMetricStatus';
import { V2beta2ContainerResourceMetricSource } from './v2beta2ContainerResourceMetricSource';
import { V2beta2ContainerResourceMetricStatus } from './v2beta2ContainerResourceMetricStatus';
import { V2beta2CrossVersionObjectReference } from './v2beta2CrossVersionObjectReference';
import { V2beta2ExternalMetricSource } from './v2beta2ExternalMetricSource';
import { V2beta2ExternalMetricStatus } from './v2beta2ExternalMetricStatus';
import { V2beta2HPAScalingPolicy } from './v2beta2HPAScalingPolicy';
import { V2beta2HPAScalingRules } from './v2beta2HPAScalingRules';
import { V2beta2HorizontalPodAutoscaler } from './v2beta2HorizontalPodAutoscaler';
import { V2beta2HorizontalPodAutoscalerBehavior } from './v2beta2HorizontalPodAutoscalerBehavior';
import { V2beta2HorizontalPodAutoscalerCondition } from './v2beta2HorizontalPodAutoscalerCondition';
import { V2beta2HorizontalPodAutoscalerList } from './v2beta2HorizontalPodAutoscalerList';
import { V2beta2HorizontalPodAutoscalerSpec } from './v2beta2HorizontalPodAutoscalerSpec';
import { V2beta2HorizontalPodAutoscalerStatus } from './v2beta2HorizontalPodAutoscalerStatus';
import { V2beta2MetricIdentifier } from './v2beta2MetricIdentifier';
import { V2beta2MetricSpec } from './v2beta2MetricSpec';
import { V2beta2MetricStatus } from './v2beta2MetricStatus';
import { V2beta2MetricTarget } from './v2beta2MetricTarget';
import { V2beta2MetricValueStatus } from './v2beta2MetricValueStatus';
import { V2beta2ObjectMetricSource } from './v2beta2ObjectMetricSource';
import { V2beta2ObjectMetricStatus } from './v2beta2ObjectMetricStatus';
import { V2beta2PodsMetricSource } from './v2beta2PodsMetricSource';
import { V2beta2PodsMetricStatus } from './v2beta2PodsMetricStatus';
import { V2beta2ResourceMetricSource } from './v2beta2ResourceMetricSource';
import { V2beta2ResourceMetricStatus } from './v2beta2ResourceMetricStatus';
import { VersionInfo } from './versionInfo';

/* tslint:disable:no-unused-variable */
let primitives = ['string', 'boolean', 'double', 'integer', 'long', 'float', 'number', 'any'];

let enumsMap: { [index: string]: any } = {};

let typeMap: { [index: string]: any } = {
  AdmissionregistrationV1ServiceReference: AdmissionregistrationV1ServiceReference,
  AdmissionregistrationV1WebhookClientConfig: AdmissionregistrationV1WebhookClientConfig,
  ApiextensionsV1ServiceReference: ApiextensionsV1ServiceReference,
  ApiextensionsV1WebhookClientConfig: ApiextensionsV1WebhookClientConfig,
  ApiregistrationV1ServiceReference: ApiregistrationV1ServiceReference,
  AuthenticationV1TokenRequest: AuthenticationV1TokenRequest,
  CoreV1EndpointPort: CoreV1EndpointPort,
  CoreV1Event: CoreV1Event,
  CoreV1EventList: CoreV1EventList,
  CoreV1EventSeries: CoreV1EventSeries,
  DiscoveryV1EndpointPort: DiscoveryV1EndpointPort,
  EventsV1Event: EventsV1Event,
  EventsV1EventList: EventsV1EventList,
  EventsV1EventSeries: EventsV1EventSeries,
  StorageV1TokenRequest: StorageV1TokenRequest,
  V1APIGroup: V1APIGroup,
  V1APIGroupList: V1APIGroupList,
  V1APIResource: V1APIResource,
  V1APIResourceList: V1APIResourceList,
  V1APIService: V1APIService,
  V1APIServiceCondition: V1APIServiceCondition,
  V1APIServiceList: V1APIServiceList,
  V1APIServiceSpec: V1APIServiceSpec,
  V1APIServiceStatus: V1APIServiceStatus,
  V1APIVersions: V1APIVersions,
  V1AWSElasticBlockStoreVolumeSource: V1AWSElasticBlockStoreVolumeSource,
  V1Affinity: V1Affinity,
  V1AggregationRule: V1AggregationRule,
  V1AttachedVolume: V1AttachedVolume,
  V1AzureDiskVolumeSource: V1AzureDiskVolumeSource,
  V1AzureFilePersistentVolumeSource: V1AzureFilePersistentVolumeSource,
  V1AzureFileVolumeSource: V1AzureFileVolumeSource,
  V1Binding: V1Binding,
  V1BoundObjectReference: V1BoundObjectReference,
  V1CSIDriver: V1CSIDriver,
  V1CSIDriverList: V1CSIDriverList,
  V1CSIDriverSpec: V1CSIDriverSpec,
  V1CSINode: V1CSINode,
  V1CSINodeDriver: V1CSINodeDriver,
  V1CSINodeList: V1CSINodeList,
  V1CSINodeSpec: V1CSINodeSpec,
  V1CSIPersistentVolumeSource: V1CSIPersistentVolumeSource,
  V1CSIVolumeSource: V1CSIVolumeSource,
  V1Capabilities: V1Capabilities,
  V1CephFSPersistentVolumeSource: V1CephFSPersistentVolumeSource,
  V1CephFSVolumeSource: V1CephFSVolumeSource,
  V1CertificateSigningRequest: V1CertificateSigningRequest,
  V1CertificateSigningRequestCondition: V1CertificateSigningRequestCondition,
  V1CertificateSigningRequestList: V1CertificateSigningRequestList,
  V1CertificateSigningRequestSpec: V1CertificateSigningRequestSpec,
  V1CertificateSigningRequestStatus: V1CertificateSigningRequestStatus,
  V1CinderPersistentVolumeSource: V1CinderPersistentVolumeSource,
  V1CinderVolumeSource: V1CinderVolumeSource,
  V1ClientIPConfig: V1ClientIPConfig,
  V1ClusterRole: V1ClusterRole,
  V1ClusterRoleBinding: V1ClusterRoleBinding,
  V1ClusterRoleBindingList: V1ClusterRoleBindingList,
  V1ClusterRoleList: V1ClusterRoleList,
  V1ComponentCondition: V1ComponentCondition,
  V1ComponentStatus: V1ComponentStatus,
  V1ComponentStatusList: V1ComponentStatusList,
  V1Condition: V1Condition,
  V1ConfigMap: V1ConfigMap,
  V1ConfigMapEnvSource: V1ConfigMapEnvSource,
  V1ConfigMapKeySelector: V1ConfigMapKeySelector,
  V1ConfigMapList: V1ConfigMapList,
  V1ConfigMapNodeConfigSource: V1ConfigMapNodeConfigSource,
  V1ConfigMapProjection: V1ConfigMapProjection,
  V1ConfigMapVolumeSource: V1ConfigMapVolumeSource,
  V1Container: V1Container,
  V1ContainerImage: V1ContainerImage,
  V1ContainerPort: V1ContainerPort,
  V1ContainerState: V1ContainerState,
  V1ContainerStateRunning: V1ContainerStateRunning,
  V1ContainerStateTerminated: V1ContainerStateTerminated,
  V1ContainerStateWaiting: V1ContainerStateWaiting,
  V1ContainerStatus: V1ContainerStatus,
  V1ControllerRevision: V1ControllerRevision,
  V1ControllerRevisionList: V1ControllerRevisionList,
  V1CronJob: V1CronJob,
  V1CronJobList: V1CronJobList,
  V1CronJobSpec: V1CronJobSpec,
  V1CronJobStatus: V1CronJobStatus,
  V1CrossVersionObjectReference: V1CrossVersionObjectReference,
  V1CustomResourceColumnDefinition: V1CustomResourceColumnDefinition,
  V1CustomResourceConversion: V1CustomResourceConversion,
  V1CustomResourceDefinition: V1CustomResourceDefinition,
  V1CustomResourceDefinitionCondition: V1CustomResourceDefinitionCondition,
  V1CustomResourceDefinitionList: V1CustomResourceDefinitionList,
  V1CustomResourceDefinitionNames: V1CustomResourceDefinitionNames,
  V1CustomResourceDefinitionSpec: V1CustomResourceDefinitionSpec,
  V1CustomResourceDefinitionStatus: V1CustomResourceDefinitionStatus,
  V1CustomResourceDefinitionVersion: V1CustomResourceDefinitionVersion,
  V1CustomResourceSubresourceScale: V1CustomResourceSubresourceScale,
  V1CustomResourceSubresources: V1CustomResourceSubresources,
  V1CustomResourceValidation: V1CustomResourceValidation,
  V1DaemonEndpoint: V1DaemonEndpoint,
  V1DaemonSet: V1DaemonSet,
  V1DaemonSetCondition: V1DaemonSetCondition,
  V1DaemonSetList: V1DaemonSetList,
  V1DaemonSetSpec: V1DaemonSetSpec,
  V1DaemonSetStatus: V1DaemonSetStatus,
  V1DaemonSetUpdateStrategy: V1DaemonSetUpdateStrategy,
  V1DeleteOptions: V1DeleteOptions,
  V1Deployment: V1Deployment,
  V1DeploymentCondition: V1DeploymentCondition,
  V1DeploymentList: V1DeploymentList,
  V1DeploymentSpec: V1DeploymentSpec,
  V1DeploymentStatus: V1DeploymentStatus,
  V1DeploymentStrategy: V1DeploymentStrategy,
  V1DownwardAPIProjection: V1DownwardAPIProjection,
  V1DownwardAPIVolumeFile: V1DownwardAPIVolumeFile,
  V1DownwardAPIVolumeSource: V1DownwardAPIVolumeSource,
  V1EmptyDirVolumeSource: V1EmptyDirVolumeSource,
  V1Endpoint: V1Endpoint,
  V1EndpointAddress: V1EndpointAddress,
  V1EndpointConditions: V1EndpointConditions,
  V1EndpointHints: V1EndpointHints,
  V1EndpointSlice: V1EndpointSlice,
  V1EndpointSliceList: V1EndpointSliceList,
  V1EndpointSubset: V1EndpointSubset,
  V1Endpoints: V1Endpoints,
  V1EndpointsList: V1EndpointsList,
  V1EnvFromSource: V1EnvFromSource,
  V1EnvVar: V1EnvVar,
  V1EnvVarSource: V1EnvVarSource,
  V1EphemeralContainer: V1EphemeralContainer,
  V1EphemeralVolumeSource: V1EphemeralVolumeSource,
  V1EventSource: V1EventSource,
  V1Eviction: V1Eviction,
  V1ExecAction: V1ExecAction,
  V1ExternalDocumentation: V1ExternalDocumentation,
  V1FCVolumeSource: V1FCVolumeSource,
  V1FlexPersistentVolumeSource: V1FlexPersistentVolumeSource,
  V1FlexVolumeSource: V1FlexVolumeSource,
  V1FlockerVolumeSource: V1FlockerVolumeSource,
  V1ForZone: V1ForZone,
  V1GCEPersistentDiskVolumeSource: V1GCEPersistentDiskVolumeSource,
  V1GitRepoVolumeSource: V1GitRepoVolumeSource,
  V1GlusterfsPersistentVolumeSource: V1GlusterfsPersistentVolumeSource,
  V1GlusterfsVolumeSource: V1GlusterfsVolumeSource,
  V1GroupVersionForDiscovery: V1GroupVersionForDiscovery,
  V1HTTPGetAction: V1HTTPGetAction,
  V1HTTPHeader: V1HTTPHeader,
  V1HTTPIngressPath: V1HTTPIngressPath,
  V1HTTPIngressRuleValue: V1HTTPIngressRuleValue,
  V1Handler: V1Handler,
  V1HorizontalPodAutoscaler: V1HorizontalPodAutoscaler,
  V1HorizontalPodAutoscalerList: V1HorizontalPodAutoscalerList,
  V1HorizontalPodAutoscalerSpec: V1HorizontalPodAutoscalerSpec,
  V1HorizontalPodAutoscalerStatus: V1HorizontalPodAutoscalerStatus,
  V1HostAlias: V1HostAlias,
  V1HostPathVolumeSource: V1HostPathVolumeSource,
  V1IPBlock: V1IPBlock,
  V1ISCSIPersistentVolumeSource: V1ISCSIPersistentVolumeSource,
  V1ISCSIVolumeSource: V1ISCSIVolumeSource,
  V1Ingress: V1Ingress,
  V1IngressBackend: V1IngressBackend,
  V1IngressClass: V1IngressClass,
  V1IngressClassList: V1IngressClassList,
  V1IngressClassParametersReference: V1IngressClassParametersReference,
  V1IngressClassSpec: V1IngressClassSpec,
  V1IngressList: V1IngressList,
  V1IngressRule: V1IngressRule,
  V1IngressServiceBackend: V1IngressServiceBackend,
  V1IngressSpec: V1IngressSpec,
  V1IngressStatus: V1IngressStatus,
  V1IngressTLS: V1IngressTLS,
  V1JSONSchemaProps: V1JSONSchemaProps,
  V1Job: V1Job,
  V1JobCondition: V1JobCondition,
  V1JobList: V1JobList,
  V1JobSpec: V1JobSpec,
  V1JobStatus: V1JobStatus,
  V1JobTemplateSpec: V1JobTemplateSpec,
  V1KeyToPath: V1KeyToPath,
  V1LabelSelector: V1LabelSelector,
  V1LabelSelectorRequirement: V1LabelSelectorRequirement,
  V1Lease: V1Lease,
  V1LeaseList: V1LeaseList,
  V1LeaseSpec: V1LeaseSpec,
  V1Lifecycle: V1Lifecycle,
  V1LimitRange: V1LimitRange,
  V1LimitRangeItem: V1LimitRangeItem,
  V1LimitRangeList: V1LimitRangeList,
  V1LimitRangeSpec: V1LimitRangeSpec,
  V1ListMeta: V1ListMeta,
  V1LoadBalancerIngress: V1LoadBalancerIngress,
  V1LoadBalancerStatus: V1LoadBalancerStatus,
  V1LocalObjectReference: V1LocalObjectReference,
  V1LocalSubjectAccessReview: V1LocalSubjectAccessReview,
  V1LocalVolumeSource: V1LocalVolumeSource,
  V1ManagedFieldsEntry: V1ManagedFieldsEntry,
  V1MutatingWebhook: V1MutatingWebhook,
  V1MutatingWebhookConfiguration: V1MutatingWebhookConfiguration,
  V1MutatingWebhookConfigurationList: V1MutatingWebhookConfigurationList,
  V1NFSVolumeSource: V1NFSVolumeSource,
  V1Namespace: V1Namespace,
  V1NamespaceCondition: V1NamespaceCondition,
  V1NamespaceList: V1NamespaceList,
  V1NamespaceSpec: V1NamespaceSpec,
  V1NamespaceStatus: V1NamespaceStatus,
  V1NetworkPolicy: V1NetworkPolicy,
  V1NetworkPolicyEgressRule: V1NetworkPolicyEgressRule,
  V1NetworkPolicyIngressRule: V1NetworkPolicyIngressRule,
  V1NetworkPolicyList: V1NetworkPolicyList,
  V1NetworkPolicyPeer: V1NetworkPolicyPeer,
  V1NetworkPolicyPort: V1NetworkPolicyPort,
  V1NetworkPolicySpec: V1NetworkPolicySpec,
  V1Node: V1Node,
  V1NodeAddress: V1NodeAddress,
  V1NodeAffinity: V1NodeAffinity,
  V1NodeCondition: V1NodeCondition,
  V1NodeConfigSource: V1NodeConfigSource,
  V1NodeConfigStatus: V1NodeConfigStatus,
  V1NodeDaemonEndpoints: V1NodeDaemonEndpoints,
  V1NodeList: V1NodeList,
  V1NodeSelector: V1NodeSelector,
  V1NodeSelectorRequirement: V1NodeSelectorRequirement,
  V1NodeSelectorTerm: V1NodeSelectorTerm,
  V1NodeSpec: V1NodeSpec,
  V1NodeStatus: V1NodeStatus,
  V1NodeSystemInfo: V1NodeSystemInfo,
  V1NonResourceAttributes: V1NonResourceAttributes,
  V1NonResourceRule: V1NonResourceRule,
  V1ObjectFieldSelector: V1ObjectFieldSelector,
  V1ObjectMeta: V1ObjectMeta,
  V1ObjectReference: V1ObjectReference,
  V1Overhead: V1Overhead,
  V1OwnerReference: V1OwnerReference,
  V1PersistentVolume: V1PersistentVolume,
  V1PersistentVolumeClaim: V1PersistentVolumeClaim,
  V1PersistentVolumeClaimCondition: V1PersistentVolumeClaimCondition,
  V1PersistentVolumeClaimList: V1PersistentVolumeClaimList,
  V1PersistentVolumeClaimSpec: V1PersistentVolumeClaimSpec,
  V1PersistentVolumeClaimStatus: V1PersistentVolumeClaimStatus,
  V1PersistentVolumeClaimTemplate: V1PersistentVolumeClaimTemplate,
  V1PersistentVolumeClaimVolumeSource: V1PersistentVolumeClaimVolumeSource,
  V1PersistentVolumeList: V1PersistentVolumeList,
  V1PersistentVolumeSpec: V1PersistentVolumeSpec,
  V1PersistentVolumeStatus: V1PersistentVolumeStatus,
  V1PhotonPersistentDiskVolumeSource: V1PhotonPersistentDiskVolumeSource,
  V1Pod: V1Pod,
  V1PodAffinity: V1PodAffinity,
  V1PodAffinityTerm: V1PodAffinityTerm,
  V1PodAntiAffinity: V1PodAntiAffinity,
  V1PodCondition: V1PodCondition,
  V1PodDNSConfig: V1PodDNSConfig,
  V1PodDNSConfigOption: V1PodDNSConfigOption,
  V1PodDisruptionBudget: V1PodDisruptionBudget,
  V1PodDisruptionBudgetList: V1PodDisruptionBudgetList,
  V1PodDisruptionBudgetSpec: V1PodDisruptionBudgetSpec,
  V1PodDisruptionBudgetStatus: V1PodDisruptionBudgetStatus,
  V1PodIP: V1PodIP,
  V1PodList: V1PodList,
  V1PodReadinessGate: V1PodReadinessGate,
  V1PodSecurityContext: V1PodSecurityContext,
  V1PodSpec: V1PodSpec,
  V1PodStatus: V1PodStatus,
  V1PodTemplate: V1PodTemplate,
  V1PodTemplateList: V1PodTemplateList,
  V1PodTemplateSpec: V1PodTemplateSpec,
  V1PolicyRule: V1PolicyRule,
  V1PortStatus: V1PortStatus,
  V1PortworxVolumeSource: V1PortworxVolumeSource,
  V1Preconditions: V1Preconditions,
  V1PreferredSchedulingTerm: V1PreferredSchedulingTerm,
  V1PriorityClass: V1PriorityClass,
  V1PriorityClassList: V1PriorityClassList,
  V1Probe: V1Probe,
  V1ProjectedVolumeSource: V1ProjectedVolumeSource,
  V1QuobyteVolumeSource: V1QuobyteVolumeSource,
  V1RBDPersistentVolumeSource: V1RBDPersistentVolumeSource,
  V1RBDVolumeSource: V1RBDVolumeSource,
  V1ReplicaSet: V1ReplicaSet,
  V1ReplicaSetCondition: V1ReplicaSetCondition,
  V1ReplicaSetList: V1ReplicaSetList,
  V1ReplicaSetSpec: V1ReplicaSetSpec,
  V1ReplicaSetStatus: V1ReplicaSetStatus,
  V1ReplicationController: V1ReplicationController,
  V1ReplicationControllerCondition: V1ReplicationControllerCondition,
  V1ReplicationControllerList: V1ReplicationControllerList,
  V1ReplicationControllerSpec: V1ReplicationControllerSpec,
  V1ReplicationControllerStatus: V1ReplicationControllerStatus,
  V1ResourceAttributes: V1ResourceAttributes,
  V1ResourceFieldSelector: V1ResourceFieldSelector,
  V1ResourceQuota: V1ResourceQuota,
  V1ResourceQuotaList: V1ResourceQuotaList,
  V1ResourceQuotaSpec: V1ResourceQuotaSpec,
  V1ResourceQuotaStatus: V1ResourceQuotaStatus,
  V1ResourceRequirements: V1ResourceRequirements,
  V1ResourceRule: V1ResourceRule,
  V1Role: V1Role,
  V1RoleBinding: V1RoleBinding,
  V1RoleBindingList: V1RoleBindingList,
  V1RoleList: V1RoleList,
  V1RoleRef: V1RoleRef,
  V1RollingUpdateDaemonSet: V1RollingUpdateDaemonSet,
  V1RollingUpdateDeployment: V1RollingUpdateDeployment,
  V1RollingUpdateStatefulSetStrategy: V1RollingUpdateStatefulSetStrategy,
  V1RuleWithOperations: V1RuleWithOperations,
  V1RuntimeClass: V1RuntimeClass,
  V1RuntimeClassList: V1RuntimeClassList,
  V1SELinuxOptions: V1SELinuxOptions,
  V1Scale: V1Scale,
  V1ScaleIOPersistentVolumeSource: V1ScaleIOPersistentVolumeSource,
  V1ScaleIOVolumeSource: V1ScaleIOVolumeSource,
  V1ScaleSpec: V1ScaleSpec,
  V1ScaleStatus: V1ScaleStatus,
  V1Scheduling: V1Scheduling,
  V1ScopeSelector: V1ScopeSelector,
  V1ScopedResourceSelectorRequirement: V1ScopedResourceSelectorRequirement,
  V1SeccompProfile: V1SeccompProfile,
  V1Secret: V1Secret,
  V1SecretEnvSource: V1SecretEnvSource,
  V1SecretKeySelector: V1SecretKeySelector,
  V1SecretList: V1SecretList,
  V1SecretProjection: V1SecretProjection,
  V1SecretReference: V1SecretReference,
  V1SecretVolumeSource: V1SecretVolumeSource,
  V1SecurityContext: V1SecurityContext,
  V1SelfSubjectAccessReview: V1SelfSubjectAccessReview,
  V1SelfSubjectAccessReviewSpec: V1SelfSubjectAccessReviewSpec,
  V1SelfSubjectRulesReview: V1SelfSubjectRulesReview,
  V1SelfSubjectRulesReviewSpec: V1SelfSubjectRulesReviewSpec,
  V1ServerAddressByClientCIDR: V1ServerAddressByClientCIDR,
  V1Service: V1Service,
  V1ServiceAccount: V1ServiceAccount,
  V1ServiceAccountList: V1ServiceAccountList,
  V1ServiceAccountTokenProjection: V1ServiceAccountTokenProjection,
  V1ServiceBackendPort: V1ServiceBackendPort,
  V1ServiceList: V1ServiceList,
  V1ServicePort: V1ServicePort,
  V1ServiceSpec: V1ServiceSpec,
  V1ServiceStatus: V1ServiceStatus,
  V1SessionAffinityConfig: V1SessionAffinityConfig,
  V1StatefulSet: V1StatefulSet,
  V1StatefulSetCondition: V1StatefulSetCondition,
  V1StatefulSetList: V1StatefulSetList,
  V1StatefulSetSpec: V1StatefulSetSpec,
  V1StatefulSetStatus: V1StatefulSetStatus,
  V1StatefulSetUpdateStrategy: V1StatefulSetUpdateStrategy,
  V1Status: V1Status,
  V1StatusCause: V1StatusCause,
  V1StatusDetails: V1StatusDetails,
  V1StorageClass: V1StorageClass,
  V1StorageClassList: V1StorageClassList,
  V1StorageOSPersistentVolumeSource: V1StorageOSPersistentVolumeSource,
  V1StorageOSVolumeSource: V1StorageOSVolumeSource,
  V1Subject: V1Subject,
  V1SubjectAccessReview: V1SubjectAccessReview,
  V1SubjectAccessReviewSpec: V1SubjectAccessReviewSpec,
  V1SubjectAccessReviewStatus: V1SubjectAccessReviewStatus,
  V1SubjectRulesReviewStatus: V1SubjectRulesReviewStatus,
  V1Sysctl: V1Sysctl,
  V1TCPSocketAction: V1TCPSocketAction,
  V1Taint: V1Taint,
  V1TokenRequestSpec: V1TokenRequestSpec,
  V1TokenRequestStatus: V1TokenRequestStatus,
  V1TokenReview: V1TokenReview,
  V1TokenReviewSpec: V1TokenReviewSpec,
  V1TokenReviewStatus: V1TokenReviewStatus,
  V1Toleration: V1Toleration,
  V1TopologySelectorLabelRequirement: V1TopologySelectorLabelRequirement,
  V1TopologySelectorTerm: V1TopologySelectorTerm,
  V1TopologySpreadConstraint: V1TopologySpreadConstraint,
  V1TypedLocalObjectReference: V1TypedLocalObjectReference,
  V1UncountedTerminatedPods: V1UncountedTerminatedPods,
  V1UserInfo: V1UserInfo,
  V1ValidatingWebhook: V1ValidatingWebhook,
  V1ValidatingWebhookConfiguration: V1ValidatingWebhookConfiguration,
  V1ValidatingWebhookConfigurationList: V1ValidatingWebhookConfigurationList,
  V1Volume: V1Volume,
  V1VolumeAttachment: V1VolumeAttachment,
  V1VolumeAttachmentList: V1VolumeAttachmentList,
  V1VolumeAttachmentSource: V1VolumeAttachmentSource,
  V1VolumeAttachmentSpec: V1VolumeAttachmentSpec,
  V1VolumeAttachmentStatus: V1VolumeAttachmentStatus,
  V1VolumeDevice: V1VolumeDevice,
  V1VolumeError: V1VolumeError,
  V1VolumeMount: V1VolumeMount,
  V1VolumeNodeAffinity: V1VolumeNodeAffinity,
  V1VolumeNodeResources: V1VolumeNodeResources,
  V1VolumeProjection: V1VolumeProjection,
  V1VsphereVirtualDiskVolumeSource: V1VsphereVirtualDiskVolumeSource,
  V1WatchEvent: V1WatchEvent,
  V1WebhookConversion: V1WebhookConversion,
  V1WeightedPodAffinityTerm: V1WeightedPodAffinityTerm,
  V1WindowsSecurityContextOptions: V1WindowsSecurityContextOptions,
  V1alpha1AggregationRule: V1alpha1AggregationRule,
  V1alpha1CSIStorageCapacity: V1alpha1CSIStorageCapacity,
  V1alpha1CSIStorageCapacityList: V1alpha1CSIStorageCapacityList,
  V1alpha1ClusterRole: V1alpha1ClusterRole,
  V1alpha1ClusterRoleBinding: V1alpha1ClusterRoleBinding,
  V1alpha1ClusterRoleBindingList: V1alpha1ClusterRoleBindingList,
  V1alpha1ClusterRoleList: V1alpha1ClusterRoleList,
  V1alpha1Overhead: V1alpha1Overhead,
  V1alpha1PolicyRule: V1alpha1PolicyRule,
  V1alpha1PriorityClass: V1alpha1PriorityClass,
  V1alpha1PriorityClassList: V1alpha1PriorityClassList,
  V1alpha1Role: V1alpha1Role,
  V1alpha1RoleBinding: V1alpha1RoleBinding,
  V1alpha1RoleBindingList: V1alpha1RoleBindingList,
  V1alpha1RoleList: V1alpha1RoleList,
  V1alpha1RoleRef: V1alpha1RoleRef,
  V1alpha1RuntimeClass: V1alpha1RuntimeClass,
  V1alpha1RuntimeClassList: V1alpha1RuntimeClassList,
  V1alpha1RuntimeClassSpec: V1alpha1RuntimeClassSpec,
  V1alpha1Scheduling: V1alpha1Scheduling,
  V1alpha1ServerStorageVersion: V1alpha1ServerStorageVersion,
  V1alpha1StorageVersion: V1alpha1StorageVersion,
  V1alpha1StorageVersionCondition: V1alpha1StorageVersionCondition,
  V1alpha1StorageVersionList: V1alpha1StorageVersionList,
  V1alpha1StorageVersionStatus: V1alpha1StorageVersionStatus,
  V1alpha1Subject: V1alpha1Subject,
  V1alpha1VolumeAttachment: V1alpha1VolumeAttachment,
  V1alpha1VolumeAttachmentList: V1alpha1VolumeAttachmentList,
  V1alpha1VolumeAttachmentSource: V1alpha1VolumeAttachmentSource,
  V1alpha1VolumeAttachmentSpec: V1alpha1VolumeAttachmentSpec,
  V1alpha1VolumeAttachmentStatus: V1alpha1VolumeAttachmentStatus,
  V1alpha1VolumeError: V1alpha1VolumeError,
  V1beta1AllowedCSIDriver: V1beta1AllowedCSIDriver,
  V1beta1AllowedFlexVolume: V1beta1AllowedFlexVolume,
  V1beta1AllowedHostPath: V1beta1AllowedHostPath,
  V1beta1CSIStorageCapacity: V1beta1CSIStorageCapacity,
  V1beta1CSIStorageCapacityList: V1beta1CSIStorageCapacityList,
  V1beta1CronJob: V1beta1CronJob,
  V1beta1CronJobList: V1beta1CronJobList,
  V1beta1CronJobSpec: V1beta1CronJobSpec,
  V1beta1CronJobStatus: V1beta1CronJobStatus,
  V1beta1Endpoint: V1beta1Endpoint,
  V1beta1EndpointConditions: V1beta1EndpointConditions,
  V1beta1EndpointHints: V1beta1EndpointHints,
  V1beta1EndpointPort: V1beta1EndpointPort,
  V1beta1EndpointSlice: V1beta1EndpointSlice,
  V1beta1EndpointSliceList: V1beta1EndpointSliceList,
  V1beta1Event: V1beta1Event,
  V1beta1EventList: V1beta1EventList,
  V1beta1EventSeries: V1beta1EventSeries,
  V1beta1FSGroupStrategyOptions: V1beta1FSGroupStrategyOptions,
  V1beta1FlowDistinguisherMethod: V1beta1FlowDistinguisherMethod,
  V1beta1FlowSchema: V1beta1FlowSchema,
  V1beta1FlowSchemaCondition: V1beta1FlowSchemaCondition,
  V1beta1FlowSchemaList: V1beta1FlowSchemaList,
  V1beta1FlowSchemaSpec: V1beta1FlowSchemaSpec,
  V1beta1FlowSchemaStatus: V1beta1FlowSchemaStatus,
  V1beta1ForZone: V1beta1ForZone,
  V1beta1GroupSubject: V1beta1GroupSubject,
  V1beta1HostPortRange: V1beta1HostPortRange,
  V1beta1IDRange: V1beta1IDRange,
  V1beta1JobTemplateSpec: V1beta1JobTemplateSpec,
  V1beta1LimitResponse: V1beta1LimitResponse,
  V1beta1LimitedPriorityLevelConfiguration: V1beta1LimitedPriorityLevelConfiguration,
  V1beta1NonResourcePolicyRule: V1beta1NonResourcePolicyRule,
  V1beta1Overhead: V1beta1Overhead,
  V1beta1PodDisruptionBudget: V1beta1PodDisruptionBudget,
  V1beta1PodDisruptionBudgetList: V1beta1PodDisruptionBudgetList,
  V1beta1PodDisruptionBudgetSpec: V1beta1PodDisruptionBudgetSpec,
  V1beta1PodDisruptionBudgetStatus: V1beta1PodDisruptionBudgetStatus,
  V1beta1PodSecurityPolicy: V1beta1PodSecurityPolicy,
  V1beta1PodSecurityPolicyList: V1beta1PodSecurityPolicyList,
  V1beta1PodSecurityPolicySpec: V1beta1PodSecurityPolicySpec,
  V1beta1PolicyRulesWithSubjects: V1beta1PolicyRulesWithSubjects,
  V1beta1PriorityLevelConfiguration: V1beta1PriorityLevelConfiguration,
  V1beta1PriorityLevelConfigurationCondition: V1beta1PriorityLevelConfigurationCondition,
  V1beta1PriorityLevelConfigurationList: V1beta1PriorityLevelConfigurationList,
  V1beta1PriorityLevelConfigurationReference: V1beta1PriorityLevelConfigurationReference,
  V1beta1PriorityLevelConfigurationSpec: V1beta1PriorityLevelConfigurationSpec,
  V1beta1PriorityLevelConfigurationStatus: V1beta1PriorityLevelConfigurationStatus,
  V1beta1QueuingConfiguration: V1beta1QueuingConfiguration,
  V1beta1ResourcePolicyRule: V1beta1ResourcePolicyRule,
  V1beta1RunAsGroupStrategyOptions: V1beta1RunAsGroupStrategyOptions,
  V1beta1RunAsUserStrategyOptions: V1beta1RunAsUserStrategyOptions,
  V1beta1RuntimeClass: V1beta1RuntimeClass,
  V1beta1RuntimeClassList: V1beta1RuntimeClassList,
  V1beta1RuntimeClassStrategyOptions: V1beta1RuntimeClassStrategyOptions,
  V1beta1SELinuxStrategyOptions: V1beta1SELinuxStrategyOptions,
  V1beta1Scheduling: V1beta1Scheduling,
  V1beta1ServiceAccountSubject: V1beta1ServiceAccountSubject,
  V1beta1Subject: V1beta1Subject,
  V1beta1SupplementalGroupsStrategyOptions: V1beta1SupplementalGroupsStrategyOptions,
  V1beta1UserSubject: V1beta1UserSubject,
  V2beta1ContainerResourceMetricSource: V2beta1ContainerResourceMetricSource,
  V2beta1ContainerResourceMetricStatus: V2beta1ContainerResourceMetricStatus,
  V2beta1CrossVersionObjectReference: V2beta1CrossVersionObjectReference,
  V2beta1ExternalMetricSource: V2beta1ExternalMetricSource,
  V2beta1ExternalMetricStatus: V2beta1ExternalMetricStatus,
  V2beta1HorizontalPodAutoscaler: V2beta1HorizontalPodAutoscaler,
  V2beta1HorizontalPodAutoscalerCondition: V2beta1HorizontalPodAutoscalerCondition,
  V2beta1HorizontalPodAutoscalerList: V2beta1HorizontalPodAutoscalerList,
  V2beta1HorizontalPodAutoscalerSpec: V2beta1HorizontalPodAutoscalerSpec,
  V2beta1HorizontalPodAutoscalerStatus: V2beta1HorizontalPodAutoscalerStatus,
  V2beta1MetricSpec: V2beta1MetricSpec,
  V2beta1MetricStatus: V2beta1MetricStatus,
  V2beta1ObjectMetricSource: V2beta1ObjectMetricSource,
  V2beta1ObjectMetricStatus: V2beta1ObjectMetricStatus,
  V2beta1PodsMetricSource: V2beta1PodsMetricSource,
  V2beta1PodsMetricStatus: V2beta1PodsMetricStatus,
  V2beta1ResourceMetricSource: V2beta1ResourceMetricSource,
  V2beta1ResourceMetricStatus: V2beta1ResourceMetricStatus,
  V2beta2ContainerResourceMetricSource: V2beta2ContainerResourceMetricSource,
  V2beta2ContainerResourceMetricStatus: V2beta2ContainerResourceMetricStatus,
  V2beta2CrossVersionObjectReference: V2beta2CrossVersionObjectReference,
  V2beta2ExternalMetricSource: V2beta2ExternalMetricSource,
  V2beta2ExternalMetricStatus: V2beta2ExternalMetricStatus,
  V2beta2HPAScalingPolicy: V2beta2HPAScalingPolicy,
  V2beta2HPAScalingRules: V2beta2HPAScalingRules,
  V2beta2HorizontalPodAutoscaler: V2beta2HorizontalPodAutoscaler,
  V2beta2HorizontalPodAutoscalerBehavior: V2beta2HorizontalPodAutoscalerBehavior,
  V2beta2HorizontalPodAutoscalerCondition: V2beta2HorizontalPodAutoscalerCondition,
  V2beta2HorizontalPodAutoscalerList: V2beta2HorizontalPodAutoscalerList,
  V2beta2HorizontalPodAutoscalerSpec: V2beta2HorizontalPodAutoscalerSpec,
  V2beta2HorizontalPodAutoscalerStatus: V2beta2HorizontalPodAutoscalerStatus,
  V2beta2MetricIdentifier: V2beta2MetricIdentifier,
  V2beta2MetricSpec: V2beta2MetricSpec,
  V2beta2MetricStatus: V2beta2MetricStatus,
  V2beta2MetricTarget: V2beta2MetricTarget,
  V2beta2MetricValueStatus: V2beta2MetricValueStatus,
  V2beta2ObjectMetricSource: V2beta2ObjectMetricSource,
  V2beta2ObjectMetricStatus: V2beta2ObjectMetricStatus,
  V2beta2PodsMetricSource: V2beta2PodsMetricSource,
  V2beta2PodsMetricStatus: V2beta2PodsMetricStatus,
  V2beta2ResourceMetricSource: V2beta2ResourceMetricSource,
  V2beta2ResourceMetricStatus: V2beta2ResourceMetricStatus,
  VersionInfo: VersionInfo,
};

export class ObjectSerializer {
  static findCorrectType(data: any, expectedType: string) {
    if (data === undefined) {
      return expectedType;
    } else if (primitives.indexOf(expectedType.toLowerCase()) !== -1) {
      return expectedType;
    } else if (expectedType === 'Date') {
      return expectedType;
    } else {
      if (enumsMap[expectedType]) {
        return expectedType;
      }

      if (!typeMap[expectedType]) {
        return expectedType; // w/e we don't know the type
      }

      // Check the discriminator
      let discriminatorProperty = typeMap[expectedType].discriminator;
      if (discriminatorProperty == null) {
        return expectedType; // the type does not have a discriminator. use it.
      } else {
        if (data[discriminatorProperty]) {
          var discriminatorType = data[discriminatorProperty];
          if (typeMap[discriminatorType]) {
            return discriminatorType; // use the type given in the discriminator
          } else {
            return expectedType; // discriminator did not map to a type
          }
        } else {
          return expectedType; // discriminator was not present (or an empty string)
        }
      }
    }
  }

  static serialize(data: any, type: string) {
    if (data === undefined) {
      return data;
    } else if (primitives.indexOf(type.toLowerCase()) !== -1) {
      return data;
    } else if (type.lastIndexOf('Array<', 0) === 0) {
      // string.startsWith pre es6
      let subType: string = type.replace('Array<', ''); // Array<Type> => Type>
      subType = subType.substring(0, subType.length - 1); // Type> => Type
      let transformedData: any[] = [];
      for (let index = 0; index < data.length; index++) {
        let datum = data[index];
        transformedData.push(ObjectSerializer.serialize(datum, subType));
      }
      return transformedData;
    } else if (type === 'Date') {
      return data.toISOString();
    } else {
      if (enumsMap[type]) {
        return data;
      }
      if (!typeMap[type]) {
        // in case we dont know the type
        return data;
      }

      // Get the actual type of this object
      type = this.findCorrectType(data, type);

      // get the map for the correct type.
      let attributeTypes = typeMap[type].getAttributeTypeMap();
      let instance: { [index: string]: any } = {};
      for (let index = 0; index < attributeTypes.length; index++) {
        let attributeType = attributeTypes[index];
        instance[attributeType.baseName] = ObjectSerializer.serialize(data[attributeType.name], attributeType.type);
      }
      return instance;
    }
  }

  static deserialize(data: any, type: string) {
    // polymorphism may change the actual type.
    type = ObjectSerializer.findCorrectType(data, type);
    if (data === undefined) {
      return data;
    } else if (primitives.indexOf(type.toLowerCase()) !== -1) {
      return data;
    } else if (type.lastIndexOf('Array<', 0) === 0) {
      // string.startsWith pre es6
      let subType: string = type.replace('Array<', ''); // Array<Type> => Type>
      subType = subType.substring(0, subType.length - 1); // Type> => Type
      let transformedData: any[] = [];
      for (let index = 0; index < data.length; index++) {
        let datum = data[index];
        transformedData.push(ObjectSerializer.deserialize(datum, subType));
      }
      return transformedData;
    } else if (type === 'Date') {
      return new Date(data);
    } else {
      if (enumsMap[type]) {
        // is Enum
        return data;
      }

      if (!typeMap[type]) {
        // dont know the type
        return data;
      }
      let instance = new typeMap[type]();
      let attributeTypes = typeMap[type].getAttributeTypeMap();
      for (let index = 0; index < attributeTypes.length; index++) {
        let attributeType = attributeTypes[index];
        instance[attributeType.name] = ObjectSerializer.deserialize(data[attributeType.baseName], attributeType.type);
      }
      return instance;
    }
  }
}

export interface Authentication {
  /**
   * Apply authentication settings to header and query params.
   */
  applyToRequest(requestOptions: localVarRequest.Options): Promise<void> | void;
}

export class HttpBasicAuth implements Authentication {
  username = '';
  password = '';

  applyToRequest(requestOptions: localVarRequest.Options): void {
    requestOptions.auth = {
      username: this.username,
      password: this.password,
    };
  }
}

export class HttpBearerAuth implements Authentication {
  accessToken: string | (() => string) = '';

  applyToRequest(requestOptions: localVarRequest.Options): void {
    if (requestOptions && requestOptions.headers) {
      const accessToken = typeof this.accessToken === 'function' ? this.accessToken() : this.accessToken;
      requestOptions.headers['Authorization'] = 'Bearer ' + accessToken;
    }
  }
}

export class ApiKeyAuth implements Authentication {
  apiKey = '';

  constructor(private location: string, private paramName: string) {}

  applyToRequest(requestOptions: localVarRequest.Options): void {
    if (this.location === 'query') {
      (requestOptions.qs as any)[this.paramName] = this.apiKey;
    } else if (this.location === 'header' && requestOptions && requestOptions.headers) {
      requestOptions.headers[this.paramName] = this.apiKey;
    } else if (this.location === 'cookie' && requestOptions && requestOptions.headers) {
      if (requestOptions.headers['Cookie']) {
        requestOptions.headers['Cookie'] += '; ' + this.paramName + '=' + encodeURIComponent(this.apiKey);
      } else {
        requestOptions.headers['Cookie'] = this.paramName + '=' + encodeURIComponent(this.apiKey);
      }
    }
  }
}

export class OAuth implements Authentication {
  accessToken = '';

  applyToRequest(requestOptions: localVarRequest.Options): void {
    if (requestOptions && requestOptions.headers) {
      requestOptions.headers['Authorization'] = 'Bearer ' + this.accessToken;
    }
  }
}

export class VoidAuth implements Authentication {
  username = '';
  password = '';

  applyToRequest(_: localVarRequest.Options): void {
    // Do nothing
  }
}

export type Interceptor = (requestOptions: localVarRequest.Options) => Promise<void> | void;
