/**
 * Kubernetes
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.22.2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * SecretKeySelector selects a key of a Secret.
 */
export class V1SecretKeySelector {
  /**
   * The key of the secret to select from.  Must be a valid secret key.
   */
  'key': string;
  /**
   * Name of the referent. More info: https://kubernetes.io/docs/concepts/overview/working-with-objects/names/#names
   */
  'name'?: string;
  /**
   * Specify whether the Secret or its key must be defined
   */
  'optional'?: boolean;

  static discriminator: string | undefined = undefined;

  static attributeTypeMap: Array<{ name: string; baseName: string; type: string }> = [
    {
      name: 'key',
      baseName: 'key',
      type: 'string',
    },
    {
      name: 'name',
      baseName: 'name',
      type: 'string',
    },
    {
      name: 'optional',
      baseName: 'optional',
      type: 'boolean',
    },
  ];

  static getAttributeTypeMap() {
    return V1SecretKeySelector.attributeTypeMap;
  }
}
