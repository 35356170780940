/**
 * Kubernetes
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.22.2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * NodeCondition contains condition information for a node.
 */
export class V1NodeCondition {
  /**
   * Last time we got an update on a given condition.
   */
  'lastHeartbeatTime'?: Date;
  /**
   * Last time the condition transit from one status to another.
   */
  'lastTransitionTime'?: Date;
  /**
   * Human readable message indicating details about last transition.
   */
  'message'?: string;
  /**
   * (brief) reason for the condition\'s last transition.
   */
  'reason'?: string;
  /**
   * Status of the condition, one of True, False, Unknown.
   */
  'status': string;
  /**
   * Type of node condition.
   */
  'type': string;

  static discriminator: string | undefined = undefined;

  static attributeTypeMap: Array<{ name: string; baseName: string; type: string }> = [
    {
      name: 'lastHeartbeatTime',
      baseName: 'lastHeartbeatTime',
      type: 'Date',
    },
    {
      name: 'lastTransitionTime',
      baseName: 'lastTransitionTime',
      type: 'Date',
    },
    {
      name: 'message',
      baseName: 'message',
      type: 'string',
    },
    {
      name: 'reason',
      baseName: 'reason',
      type: 'string',
    },
    {
      name: 'status',
      baseName: 'status',
      type: 'string',
    },
    {
      name: 'type',
      baseName: 'type',
      type: 'string',
    },
  ];

  static getAttributeTypeMap() {
    return V1NodeCondition.attributeTypeMap;
  }
}
