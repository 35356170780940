/**
 * Kubernetes
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.22.2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * ContainerStateRunning is a running state of a container.
 */
export class V1ContainerStateRunning {
  /**
   * Time at which the container was last (re-)started
   */
  'startedAt'?: Date;

  static discriminator: string | undefined = undefined;

  static attributeTypeMap: Array<{ name: string; baseName: string; type: string }> = [
    {
      name: 'startedAt',
      baseName: 'startedAt',
      type: 'Date',
    },
  ];

  static getAttributeTypeMap() {
    return V1ContainerStateRunning.attributeTypeMap;
  }
}
