/**
 * Kubernetes
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.22.2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * AttachedVolume describes a volume attached to a node
 */
export class V1AttachedVolume {
  /**
   * DevicePath represents the device path where the volume should be available
   */
  'devicePath': string;
  /**
   * Name of the attached volume
   */
  'name': string;

  static discriminator: string | undefined = undefined;

  static attributeTypeMap: Array<{ name: string; baseName: string; type: string }> = [
    {
      name: 'devicePath',
      baseName: 'devicePath',
      type: 'string',
    },
    {
      name: 'name',
      baseName: 'name',
      type: 'string',
    },
  ];

  static getAttributeTypeMap() {
    return V1AttachedVolume.attributeTypeMap;
  }
}
