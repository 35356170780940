/**
 * Kubernetes
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.22.2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { V1beta1GroupSubject } from './v1beta1GroupSubject';
import { V1beta1ServiceAccountSubject } from './v1beta1ServiceAccountSubject';
import { V1beta1UserSubject } from './v1beta1UserSubject';

/**
 * Subject matches the originator of a request, as identified by the request authentication system. There are three ways of matching an originator; by user, group, or service account.
 */
export class V1beta1Subject {
  'group'?: V1beta1GroupSubject;
  /**
   * `kind` indicates which one of the other fields is non-empty. Required
   */
  'kind': string;
  'serviceAccount'?: V1beta1ServiceAccountSubject;
  'user'?: V1beta1UserSubject;

  static discriminator: string | undefined = undefined;

  static attributeTypeMap: Array<{ name: string; baseName: string; type: string }> = [
    {
      name: 'group',
      baseName: 'group',
      type: 'V1beta1GroupSubject',
    },
    {
      name: 'kind',
      baseName: 'kind',
      type: 'string',
    },
    {
      name: 'serviceAccount',
      baseName: 'serviceAccount',
      type: 'V1beta1ServiceAccountSubject',
    },
    {
      name: 'user',
      baseName: 'user',
      type: 'V1beta1UserSubject',
    },
  ];

  static getAttributeTypeMap() {
    return V1beta1Subject.attributeTypeMap;
  }
}
