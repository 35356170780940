/**
 * Kubernetes
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.22.2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { V1SecretReference } from './v1SecretReference';

/**
 * Represents a Ceph Filesystem mount that lasts the lifetime of a pod Cephfs volumes do not support ownership management or SELinux relabeling.
 */
export class V1CephFSPersistentVolumeSource {
  /**
   * Required: Monitors is a collection of Ceph monitors More info: https://examples.k8s.io/volumes/cephfs/README.md#how-to-use-it
   */
  'monitors': string[];
  /**
   * Optional: Used as the mounted root, rather than the full Ceph tree, default is /
   */
  'path'?: string;
  /**
   * Optional: Defaults to false (read/write). ReadOnly here will force the ReadOnly setting in VolumeMounts. More info: https://examples.k8s.io/volumes/cephfs/README.md#how-to-use-it
   */
  'readOnly'?: boolean;
  /**
   * Optional: SecretFile is the path to key ring for User, default is /etc/ceph/user.secret More info: https://examples.k8s.io/volumes/cephfs/README.md#how-to-use-it
   */
  'secretFile'?: string;
  'secretRef'?: V1SecretReference;
  /**
   * Optional: User is the rados user name, default is admin More info: https://examples.k8s.io/volumes/cephfs/README.md#how-to-use-it
   */
  'user'?: string;

  static discriminator: string | undefined = undefined;

  static attributeTypeMap: Array<{ name: string; baseName: string; type: string }> = [
    {
      name: 'monitors',
      baseName: 'monitors',
      type: 'Array<string>',
    },
    {
      name: 'path',
      baseName: 'path',
      type: 'string',
    },
    {
      name: 'readOnly',
      baseName: 'readOnly',
      type: 'boolean',
    },
    {
      name: 'secretFile',
      baseName: 'secretFile',
      type: 'string',
    },
    {
      name: 'secretRef',
      baseName: 'secretRef',
      type: 'V1SecretReference',
    },
    {
      name: 'user',
      baseName: 'user',
      type: 'string',
    },
  ];

  static getAttributeTypeMap() {
    return V1CephFSPersistentVolumeSource.attributeTypeMap;
  }
}
