/**
 * Kubernetes
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.22.2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * TokenReviewSpec is a description of the token authentication request.
 */
export class V1TokenReviewSpec {
  /**
   * Audiences is a list of the identifiers that the resource server presented with the token identifies as. Audience-aware token authenticators will verify that the token was intended for at least one of the audiences in this list. If no audiences are provided, the audience will default to the audience of the Kubernetes apiserver.
   */
  'audiences'?: string[];
  /**
   * Token is the opaque bearer token.
   */
  'token'?: string;

  static discriminator: string | undefined = undefined;

  static attributeTypeMap: Array<{ name: string; baseName: string; type: string }> = [
    {
      name: 'audiences',
      baseName: 'audiences',
      type: 'Array<string>',
    },
    {
      name: 'token',
      baseName: 'token',
      type: 'string',
    },
  ];

  static getAttributeTypeMap() {
    return V1TokenReviewSpec.attributeTypeMap;
  }
}
