/**
 * Kubernetes
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.22.2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * current status of a horizontal pod autoscaler
 */
export class V1HorizontalPodAutoscalerStatus {
  /**
   * current average CPU utilization over all pods, represented as a percentage of requested CPU, e.g. 70 means that an average pod is using now 70% of its requested CPU.
   */
  'currentCPUUtilizationPercentage'?: number;
  /**
   * current number of replicas of pods managed by this autoscaler.
   */
  'currentReplicas': number;
  /**
   * desired number of replicas of pods managed by this autoscaler.
   */
  'desiredReplicas': number;
  /**
   * last time the HorizontalPodAutoscaler scaled the number of pods; used by the autoscaler to control how often the number of pods is changed.
   */
  'lastScaleTime'?: Date;
  /**
   * most recent generation observed by this autoscaler.
   */
  'observedGeneration'?: number;

  static discriminator: string | undefined = undefined;

  static attributeTypeMap: Array<{ name: string; baseName: string; type: string }> = [
    {
      name: 'currentCPUUtilizationPercentage',
      baseName: 'currentCPUUtilizationPercentage',
      type: 'number',
    },
    {
      name: 'currentReplicas',
      baseName: 'currentReplicas',
      type: 'number',
    },
    {
      name: 'desiredReplicas',
      baseName: 'desiredReplicas',
      type: 'number',
    },
    {
      name: 'lastScaleTime',
      baseName: 'lastScaleTime',
      type: 'Date',
    },
    {
      name: 'observedGeneration',
      baseName: 'observedGeneration',
      type: 'number',
    },
  ];

  static getAttributeTypeMap() {
    return V1HorizontalPodAutoscalerStatus.attributeTypeMap;
  }
}
