/**
 * Kubernetes
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.22.2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { V1LabelSelectorRequirement } from './v1LabelSelectorRequirement';

/**
 * A label selector is a label query over a set of resources. The result of matchLabels and matchExpressions are ANDed. An empty label selector matches all objects. A null label selector matches no objects.
 */
export class V1LabelSelector {
  /**
   * matchExpressions is a list of label selector requirements. The requirements are ANDed.
   */
  'matchExpressions'?: V1LabelSelectorRequirement[];
  /**
   * matchLabels is a map of {key,value} pairs. A single {key,value} in the matchLabels map is equivalent to an element of matchExpressions, whose key field is \"key\", the operator is \"In\", and the values array contains only \"value\". The requirements are ANDed.
   */
  'matchLabels'?: { [key: string]: string };

  static discriminator: string | undefined = undefined;

  static attributeTypeMap: Array<{ name: string; baseName: string; type: string }> = [
    {
      name: 'matchExpressions',
      baseName: 'matchExpressions',
      type: 'Array<V1LabelSelectorRequirement>',
    },
    {
      name: 'matchLabels',
      baseName: 'matchLabels',
      type: '{ [key: string]: string; }',
    },
  ];

  static getAttributeTypeMap() {
    return V1LabelSelector.attributeTypeMap;
  }
}
