/**
 * Kubernetes
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.22.2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { V2beta2MetricIdentifier } from './v2beta2MetricIdentifier';
import { V2beta2MetricTarget } from './v2beta2MetricTarget';

/**
 * ExternalMetricSource indicates how to scale on a metric not associated with any Kubernetes object (for example length of queue in cloud messaging service, or QPS from loadbalancer running outside of cluster).
 */
export class V2beta2ExternalMetricSource {
  'metric': V2beta2MetricIdentifier;
  'target': V2beta2MetricTarget;

  static discriminator: string | undefined = undefined;

  static attributeTypeMap: Array<{ name: string; baseName: string; type: string }> = [
    {
      name: 'metric',
      baseName: 'metric',
      type: 'V2beta2MetricIdentifier',
    },
    {
      name: 'target',
      baseName: 'target',
      type: 'V2beta2MetricTarget',
    },
  ];

  static getAttributeTypeMap() {
    return V2beta2ExternalMetricSource.attributeTypeMap;
  }
}
