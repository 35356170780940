/**
 * Kubernetes
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.22.2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * SecretReference represents a Secret Reference. It has enough information to retrieve secret in any namespace
 */
export class V1SecretReference {
  /**
   * Name is unique within a namespace to reference a secret resource.
   */
  'name'?: string;
  /**
   * Namespace defines the space within which the secret name must be unique.
   */
  'namespace'?: string;

  static discriminator: string | undefined = undefined;

  static attributeTypeMap: Array<{ name: string; baseName: string; type: string }> = [
    {
      name: 'name',
      baseName: 'name',
      type: 'string',
    },
    {
      name: 'namespace',
      baseName: 'namespace',
      type: 'string',
    },
  ];

  static getAttributeTypeMap() {
    return V1SecretReference.attributeTypeMap;
  }
}
