/**
 * Kubernetes
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.22.2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * PersistentVolumeClaimCondition contails details about state of pvc
 */
export class V1PersistentVolumeClaimCondition {
  /**
   * Last time we probed the condition.
   */
  'lastProbeTime'?: Date;
  /**
   * Last time the condition transitioned from one status to another.
   */
  'lastTransitionTime'?: Date;
  /**
   * Human-readable message indicating details about last transition.
   */
  'message'?: string;
  /**
   * Unique, this should be a short, machine understandable string that gives the reason for condition\'s last transition. If it reports \"ResizeStarted\" that means the underlying persistent volume is being resized.
   */
  'reason'?: string;
  'status': string;
  'type': string;

  static discriminator: string | undefined = undefined;

  static attributeTypeMap: Array<{ name: string; baseName: string; type: string }> = [
    {
      name: 'lastProbeTime',
      baseName: 'lastProbeTime',
      type: 'Date',
    },
    {
      name: 'lastTransitionTime',
      baseName: 'lastTransitionTime',
      type: 'Date',
    },
    {
      name: 'message',
      baseName: 'message',
      type: 'string',
    },
    {
      name: 'reason',
      baseName: 'reason',
      type: 'string',
    },
    {
      name: 'status',
      baseName: 'status',
      type: 'string',
    },
    {
      name: 'type',
      baseName: 'type',
      type: 'string',
    },
  ];

  static getAttributeTypeMap() {
    return V1PersistentVolumeClaimCondition.attributeTypeMap;
  }
}
