/**
 * Kubernetes
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.22.2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { V1NamespaceCondition } from './v1NamespaceCondition';

/**
 * NamespaceStatus is information about the current status of a Namespace.
 */
export class V1NamespaceStatus {
  /**
   * Represents the latest available observations of a namespace\'s current state.
   */
  'conditions'?: V1NamespaceCondition[];
  /**
   * Phase is the current lifecycle phase of the namespace. More info: https://kubernetes.io/docs/tasks/administer-cluster/namespaces/
   */
  'phase'?: string;

  static discriminator: string | undefined = undefined;

  static attributeTypeMap: Array<{ name: string; baseName: string; type: string }> = [
    {
      name: 'conditions',
      baseName: 'conditions',
      type: 'Array<V1NamespaceCondition>',
    },
    {
      name: 'phase',
      baseName: 'phase',
      type: 'string',
    },
  ];

  static getAttributeTypeMap() {
    return V1NamespaceStatus.attributeTypeMap;
  }
}
