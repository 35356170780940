/**
 * Kubernetes
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.22.2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * ResourceFieldSelector represents container resources (cpu, memory) and their output format
 */
export class V1ResourceFieldSelector {
  /**
   * Container name: required for volumes, optional for env vars
   */
  'containerName'?: string;
  /**
   * Specifies the output format of the exposed resources, defaults to \"1\"
   */
  'divisor'?: string;
  /**
   * Required: resource to select
   */
  'resource': string;

  static discriminator: string | undefined = undefined;

  static attributeTypeMap: Array<{ name: string; baseName: string; type: string }> = [
    {
      name: 'containerName',
      baseName: 'containerName',
      type: 'string',
    },
    {
      name: 'divisor',
      baseName: 'divisor',
      type: 'string',
    },
    {
      name: 'resource',
      baseName: 'resource',
      type: 'string',
    },
  ];

  static getAttributeTypeMap() {
    return V1ResourceFieldSelector.attributeTypeMap;
  }
}
